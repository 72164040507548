import { useEffect, useState } from "react"
import styled from "styled-components"
import { Tabs, Result, Typography } from "antd"
import {
    CodeOutlined,
    FieldTimeOutlined,
    CloseCircleOutlined,
    LoadingOutlined,
    HistoryOutlined,
    NodeExpandOutlined,
    TableOutlined,
    UserOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import { CopyBlock, github } from "react-code-blocks"

import { StyleHelpers, Colors, Spaces, Centered } from "../global"
import { EndpointData } from "../../machines/SimulatorModel"
import { TreeWrapper } from "../Designer/AccessPermissions/AllowedFieldsTree"

const { Title, Paragraph, Text } = Typography

const ResponseContainer = (props: { isLoading: boolean; response: EndpointData["response"]; type: string }) => {
    const { response, type, isLoading } = props

    if (!response) return null

    const [now, setNow] = useState(new Date())
    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date())
        }, 100)
        if (response.endTime) return () => clearInterval(interval)
    }, [response.startTime, response.endTime])

    const TabsChildrenArr = [
        {
            key: "RAW",
            label: (
                <span>
                    <CodeOutlined />
                    Raw response
                </span>
            ),
            children: (
                <TabContentWrapper>
                    <CopyBlock text={JSON.stringify(response.data, null, 4)} language="json" showLineNumbers={false} wrapLongLines theme={github} />
                </TabContentWrapper>
            ),
        },
    ]

    // FIXME: implement table view for response [Tasks]
    if (type === "List")
        TabsChildrenArr.push({
            key: "TABLE",
            label: (
                <span>
                    <TableOutlined />
                    Table
                </span>
            ),
            children: <TabContentWrapper>"Table representation of response" coming soon...</TabContentWrapper>,
        })

    if (["List", "Get"].includes(type)) {
        // TODO: transform json to tree compatible format
        // const treeData = [
        //     {
        //         title: <div>{field.name}</div>,
        //         key: parentPath ? `${parentPath}.${field.name}` : field.name,
        //         icon: <CarryOutOutlined />,
        //         selectable: false,
        //         children: children,
        //     },
        // ]

        TabsChildrenArr.push({
            key: "TREE",
            label: (
                <span>
                    <NodeExpandOutlined />
                    Tree
                </span>
            ),
            children: (
                <TabContentWrapper>
                    {/* FIXME: implement tree view [Tasks] */}
                    "Tree representation of response" coming soon...
                    <TreeWrapper>
                        {/* <Tree
                            style={{
                                background: Colors.background,
                                width: "100%",
                            }}
                            showLine={true}
                            showIcon={false}
                            defaultExpandAll
                            treeData={treeData}
                        /> */}
                    </TreeWrapper>
                </TabContentWrapper>
            ),
        })
    }

    return (
        <ResponseContainerWrapper>
            {!response.endTime && isLoading && (
                <Centered vertical style={{ paddingTop: "25px" }}>
                    <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />
                    <Title level={4}>{`API call is being executed: ${dayjs(now).diff(response.startTime, "millisecond")}ms ...`}</Title>
                </Centered>
            )}

            {response.endTime && (
                <Stats>
                    <StatInfo>
                        <FieldTimeOutlined
                            style={{
                                width: StyleHelpers.iconSize,
                                color: Colors.grayDark,
                            }}
                        />{" "}
                        <span>{`Last execution: ${dayjs(response.endTime).format("DD/MM/YY hh:mm")} (${dayjs().diff(
                            response.endTime,
                            "minute"
                        )} mins ago)`}</span>
                    </StatInfo>
                    <StatInfo>
                        <HistoryOutlined
                            style={{
                                width: StyleHelpers.iconSize,
                                color: Colors.grayDark,
                            }}
                        />
                        <span>{`Duration: ${dayjs(response.endTime).diff(response.startTime, "millisecond")}ms`}</span>
                    </StatInfo>
                    <StatInfo>
                        <UserOutlined
                            style={{
                                width: StyleHelpers.iconSize,
                                color: Colors.grayDark,
                            }}
                        />
                        <span>{`On behalf: ${response.onBehalf}`}</span>
                    </StatInfo>
                </Stats>
            )}

            {response.errors && (
                <Result
                    status="error"
                    title="API call failed"
                    subTitle={`Please check and modify the request before resubmitting`}
                    // extra={[
                    //     <Button type="primary" key="console">
                    //         Go Console
                    //     </Button>,
                    // ]}
                >
                    <div className="desc">
                        <Paragraph>
                            <Text
                                strong
                                style={{
                                    fontSize: 16,
                                }}
                            >
                                The request you submitted has the following error:
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            {response.errors.map((err) => (
                                <>
                                    <CloseCircleOutlined className="site-result-demo-error-icon" /> {err.message}
                                    <br />
                                </>
                            ))}
                        </Paragraph>
                    </div>
                </Result>
            )}

            {response.data && (
                <Tabs
                    className="response-tabs"
                    defaultActiveKey={"RAW"} // {type === "List" ? "TABLE" : type === "Get" ? "TREE" : "RAW"} FIXME: move to machine states [Tasks]
                    centered
                    onChange={(key) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // send(key) FIXME:
                    }}
                    items={TabsChildrenArr}
                />
            )}
        </ResponseContainerWrapper>
    )
}

export default ResponseContainer

const ResponseContainerWrapper = styled.div`
    .response-tabs {
        .ant-tabs-nav {
            background-color: "unset";
            box-shadow: "unset";
        }
    }
`

const Stats = styled.div`
    margin-top: ${Spaces.medium};
    display: flex;
    flex-direction: column;
    gap: ${Spaces.small};
    padding: 0 ${Spaces.large} ${Spaces.large};
`

const StatInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.small};
    color: ${Colors.grayDark};
`

const TabContentWrapper = styled.div<{ withPadding?: boolean }>`
    overflow-y: scroll;
    width: 100%;
    padding: ${Spaces.medium} ${Spaces.medium} 0;
`
