import styled from "styled-components"
import { StyleHelpers, ItemWithFadeInAnimation, Colors, Spaces } from "../global"

const APIAuthSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* position: relative; */

    width: 100vw;
    padding: 0 ${Spaces.xLarge} ${Spaces.large};
    background: white;
    box-shadow: ${StyleHelpers.boldBoxShadow};
    z-index: 1;
`

const SelectedSession = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.normal};

    padding: ${Spaces.normal};
`

const AuthSessionsListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    background-color: ${Colors.grayLight};
    border-radius: ${StyleHelpers.radiusMedium};
`
const MainBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: ${Spaces.medium};
    overflow-x: visible;
`

const VerticalLine = styled.div`
    width: 1px;
    height: 340px;
    margin: 40px ${Spaces.medium};
    background-color: ${Colors.grayNormal};
`

const SideBlock = styled.div`
    border-left: 1px solid ${Colors.grayNormal};
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin: ${Spaces.xLarge} 0;
    margin-left: ${Spaces.small};
    padding: ${Spaces.medium};
    padding-left: ${Spaces.xLarge};
    overflow-x: visible;
`

const AuthSessionsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spaces.medium};
    margin-bottom: ${Spaces.normal};

    max-height: 300px;
    padding: 5px;
    overflow-y: auto;
    overflow-x: visible;
`

const AuthSessionItem = styled(ItemWithFadeInAnimation)<{ index: number; clickable: boolean }>`
    display: flex;
    align-items: center;
    gap: ${Spaces.normal};

    padding: ${Spaces.normal};
    border-radius: ${StyleHelpers.radiusSmall};
    background-color: white;

    animation-delay: ${(props: { index: number }) => props.index * 0.15}s; /* delay animation start for each item */

    :hover {
        cursor: ${(props: { clickable: boolean }) => (props.clickable ? "pointer" : "not-allowed")};
        box-shadow: ${(props: { clickable: boolean }) => (props.clickable ? StyleHelpers.lightBoxShadow : "unset")};
        transform: ${(props: { clickable: boolean }) => (props.clickable ? "scale(1.01)" : "unset")};
    }

    span.name {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    div:last-child {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;
    }
`

const TextTag = styled.div`
    background: ${Colors.grayLight};
    color: ${Colors.grayDark};
    border-radius: ${StyleHelpers.radiusSmall};
    padding: 0 ${Spaces.normal};
    white-space: nowrap;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const NewAuthType = styled(ItemWithFadeInAnimation)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 300px;
    gap: ${Spaces.medium};
`

const LoginSignup = styled(NewAuthType)``

const EmailVerification = styled(NewAuthType)``

export {
    APIAuthSection,
    SelectedSession,
    AuthSessionsListWrapper,
    MainBlock,
    VerticalLine,
    SideBlock,
    AuthSessionsList,
    AuthSessionItem,
    TextTag,
    NewAuthType,
    LoginSignup,
    EmailVerification,
}
