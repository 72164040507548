import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useMachine } from "@xstate/react"
import { Tabs, Badge, Tooltip, Button, Typography } from "antd"
import {
    UsergroupAddOutlined,
    DeploymentUnitOutlined,
    ShareAltOutlined,
    BorderOuterOutlined,
    UploadOutlined,
    LoadingOutlined,
    RedoOutlined,
    PlayCircleOutlined,
} from "@ant-design/icons"
import styled from "styled-components"
import type { TabsProps } from "antd"

import type { User, EntityField, AccessItem, ConfigEntity } from "../coreTypes/config"
import { UsersTabContent, EntitiesTabContent, SubObjectsTabContent, EnumsTabContent, AccessPermissionsContainer } from "../components/Designer"
import { Colors, Spaces, PageWrapper, HalfContainer, StyleHelpers, Centered } from "../components/global"
import { designerMachine } from "../machines/DesignerMachine"
import dayjs from "dayjs"
import { NotificationInstance } from "antd/es/notification/interface"

const { Title } = Typography

function TabLabel(props: { tabName: string; count?: number; active: boolean }) {
    const { tabName, count, active } = props

    let icon
    let text
    switch (tabName) {
        case "users":
            icon = <UsergroupAddOutlined />
            text = "User groups"
            break
        case "entities":
            icon = <DeploymentUnitOutlined />
            text = (
                <Tooltip
                    mouseEnterDelay={0.4}
                    arrow={{ pointAtCenter: true }}
                    overlayInnerStyle={{ width: "600px" }}
                    title={
                        `A data entity is a way of organizing information. ` +
                        `It's like a category for a specific type of data, such as a product. ` +
                        `Let's say you're running a grocery store and you want to keep track of your inventory. ` +
                        `You might create a data entity called "Product" where you would store information like the name of the product, ` +
                        `the quantity in stock, and the price. This way, you can easily see how many products you have on hand and how much you need to order.`
                    }
                >
                    Data entities
                </Tooltip>
            )
            break
        case "subobjects":
            icon = <ShareAltOutlined />
            text = (
                <Tooltip
                    mouseEnterDelay={0.4}
                    arrow={{ pointAtCenter: true }}
                    overlayInnerStyle={{ width: "600px" }}
                    title={
                        `Sub-object is repetetive data structure that can be used in multiple places. ` +
                        `For example, you can have a sub-object called "Address" that contains fields like "Street", ` +
                        `"City", "State", and "Zip Code". Then, you can use this sub-object in multiple places, ` +
                        `such as for a customer's billing address and shipping address.`
                    }
                >
                    Sub-objects
                </Tooltip>
            )
            break
        case "enums":
            icon = <BorderOuterOutlined />
            text = (
                <Tooltip
                    mouseEnterDelay={0.4}
                    arrow={{ pointAtCenter: true }}
                    overlayInnerStyle={{ width: "600px" }}
                    title={
                        `List of predefined options. In programming, an enum (short for "enumeration") ` +
                        `is a type of data that allows you to define a set of named values, or "constants", ` +
                        `that represent a list of options or choices. For instance, if you were writing a program to represent the gender of a person, ` +
                        `you might use an enum to define the values "male" and "female" as discrete, predefined options.`
                    }
                >
                    Enums
                </Tooltip>
            )
            break
    }

    return (
        <span>
            {icon}
            {text}
            <Badge
                count={count || 0}
                style={{
                    marginLeft: Spaces.small,
                    backgroundColor: active ? Colors.grayLight : Colors.grayLight,
                    borderColor: active ? Colors.grayLight : Colors.grayLight,
                    color: active ? Colors.primary : Colors.grayDark,
                }}
            />
        </span>
    )
}

export default function DesignerPage(props: { notificationsApi: NotificationInstance }) {
    const { projectId } = useParams()
    const navigate = useNavigate()
    const [current, send] = useMachine(designerMachine)
    const [deploymentTimer, setDeploymentTimer] = useState(0)

    useEffect(() => {
        if (deploymentTimer > 0) {
            const interval = setInterval(() => {
                setDeploymentTimer((prevTimer) => prevTimer - 1)
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [deploymentTimer])

    useEffect(() => {
        if (!current.context.notificationsApi) {
            send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi: props.notificationsApi })
        }
        if (current.matches("none")) {
            if (projectId) {
                send({ type: "FETCH", projectId: projectId })
            }
        }
    }, [current])

    if (current.context.debug) {
        useEffect(() => {
            const cleanContext: any = { ...current.context }
            delete cleanContext.projectConfig
            console.log(
                `Designer machine state value changed:\n${JSON.stringify(current.value, null, 4)};\n\n\nWith context:\n${JSON.stringify(
                    cleanContext,
                    null,
                    4
                )}.`
            )
        }, [current.value])
    }

    if (current.matches("none")) {
        if (projectId) {
            return <></>
        } else {
            return (
                <PageWrapper>
                    <Centered vertical>
                        <Title level={2}>Missing project ID in URL...</Title>
                    </Centered>
                </PageWrapper>
            )
        }
    }

    if (current.matches("globalLoading"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />
                    <Title level={2}>Loading project configuration...</Title>
                </Centered>
            </PageWrapper>
        )

    if (current.matches("error"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <Title level={2}>Error loading project: try again...</Title>
                    <Button
                        type="primary"
                        icon={<RedoOutlined />}
                        onClick={() => {
                            send("TRY_AGAIN")
                        }}
                    >
                        Load projects
                    </Button>
                </Centered>
            </PageWrapper>
        )

    const isPermissionsContainer = [
        "designer.users.userForm.permissions.permissionForm",
        "designer.users.userForm.permissions.observePermissions",
        "designer.entities.entityForm.permissions.permissionForm",
        "designer.entities.entityForm.permissions.observePermissions",
    ].some(current.matches)
    const isUserForm = ["designer.users.userForm", "designer.users.savingConfig"].some(current.matches)
    const isEntityForm = ["designer.entities.entityForm", "designer.entities.savingConfig"].some(current.matches)
    const isSubObjectForm = ["designer.subobjects.subObjectForm", "designer.subobjects.subObjectForm.savingConfig"].some(current.matches)
    const isEnumForm = ["designer.enums.enumForm", "designer.enums.savingConfig"].some(current.matches)

    const fieldFormProps = {
        fieldForm: current.context.fieldForm,
        changeName: (fieldName: string) => {
            send("CHANGE_FIELD_NAME", { fieldName })
        },
        sendResetField: () => {
            send("RESET_FIELD")
        },
        sendDeleteField: () => {
            send("DELETE_FIELD")
        },
        sendCancelField: () => {
            send("CANCEL_FIELD")
        },
        sendSaveField: () => {
            send("SAVE_FIELD")
        },
        changeDescription: (description: string) => {
            send("CHANGE_FIELD_DESCRIPTION", { description })
        },
        changeType: (typeValue: string) => {
            send("CHANGE_FIELD_TYPE", { typeValue })
        },
        switchMandatory: () => {
            send("SWITCH_MANDATORY")
        },
        switchIsArray: () => {
            send("SWITCH_ISARRAY")
        },
        switchMutable: () => {
            send("SWITCH_MUTABLE")
        },
        changeConnectionUnderlyingFieldName: (fieldName: string) => {
            send("CHANGE_CONNECTION_UNDERLYING_FIELD_NAME", { fieldName })
        },
        setRemoteField: (fieldName: string) => {
            send("SET_REMOTE_FIELD_NAME", { fieldName })
        },
        switchUnderlyingMandatory: () => {
            send("SWITCH_UNDERLYING_MANDATORY")
        },
        switchUnderlyingIsArray: () => {
            send("SWITCH_UNDERLYING_ISARRAY")
        },
        swithcUnderlyingMutable: () => {
            send("SWITCH_UNDERLYING_MULTABLE")
        },
    }

    const isDeployable = [
        "designer.users.observeUserGroupsList",
        "designer.entities.observeEntitiesList",
        "designer.subobjects.observeSubObjectsList",
        "designer.enums.observeEnumsList",
    ].some(current.matches)

    const isDeploying = [
        "designer.users.deployingVersion",
        "designer.entities.deployingVersion",
        "designer.subobjects.deployingVersion",
        "designer.enums.deployingVersion",
    ].some(current.matches)

    const DeployButton =
        current.context.project?.deployedAtISOString &&
        current.context.project.deployedVersion!.toString() === current.context.projectConfig.apiVersion.toString() ? (
            <Button
                type="primary"
                icon={<PlayCircleOutlined />}
                disabled={!isDeployable}
                onClick={() => {
                    navigate(`/${projectId}/simulator`)
                }}
            >
                Try with "Live API"
            </Button>
        ) : (
            <Button
                type="primary"
                icon={<UploadOutlined />}
                disabled={!isDeployable || !current.context.projectConfig._id}
                loading={isDeploying}
                onClick={() => {
                    setDeploymentTimer(30)
                    send("DEPLOY_VERSION")
                }}
            >
                {isDeploying ? `Deploying... (${deploymentTimer}s)` : "Deploy version"}
            </Button>
        )

    return (
        <>
            <PageWrapper>
                <HalfContainer deductFooter>
                    <Tabs
                        defaultActiveKey="users"
                        centered
                        onChange={(key) => {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            send(key)
                        }}
                        items={
                            [
                                {
                                    key: "USERS",
                                    disabled: isEntityForm || isSubObjectForm || isEnumForm,
                                    label: (
                                        <TabLabel
                                            tabName="users"
                                            active={current.matches("designer.users")}
                                            count={current.context.projectConfig.users?.length}
                                        />
                                    ),
                                    // disabled: true,
                                    children: (
                                        <TabContentWrapper deductFooter>
                                            <UsersTabContent
                                                projectConfig={current.context.projectConfig}
                                                isObserveList={["designer.users.observeUserGroupsList", "designer.users.deployingVersion"].some(
                                                    current.matches
                                                )}
                                                isUserForm={isUserForm}
                                                isSavingForm={current.matches("designer.users.savingConfig")}
                                                isFieldForm={current.matches("designer.users.userForm.fields.fieldForm")}
                                                isPermissionForm={current.matches("designer.users.userForm.permissions.permissionForm")}
                                                sendUserForm={(user?: User) => {
                                                    send("USER_FORM", { user: user ?? undefined })
                                                }}
                                                sendFieldForm={(field?: EntityField) => {
                                                    send("FIELD_FORM", { field: field ?? undefined })
                                                }}
                                                userFormProps={{
                                                    userForm: current.context.userForm,
                                                    changeGroupName: (groupName: string) => {
                                                        send("CHANGE_USER_GROUP_NAME", { groupName })
                                                    },
                                                    changeDescription: (description: string) => {
                                                        send("CHANGE_USER_GROUP_DESCRIPTION", { description })
                                                    },
                                                    sendSaveUser: () => {
                                                        send("SAVE_USER")
                                                    },
                                                    sendResetUser: () => {
                                                        send("RESET_USER")
                                                    },
                                                    sendDeleteUser: () => {
                                                        send("DELETE_USER")
                                                    },
                                                    sendCancelUser: () => {
                                                        send("CANCEL_USER")
                                                    },
                                                    switchTrackChanges: () => {
                                                        send("SWITCH_USER_GROUP_TRACK_CHANGES")
                                                    },
                                                    switchInvitable: () => {
                                                        send("SWITCH_INVITABLE")
                                                    },
                                                    switchSignupable: () => {
                                                        send("SWITCH_SIGNUPABLE")
                                                    },
                                                    sendObservePermissions: (accessItems?: AccessItem[]) => {
                                                        if (isPermissionsContainer) {
                                                            send("HIDE_PERMISSIONS")
                                                        } else {
                                                            send("OBSERVE_PERMISSIONS", { accessItems })
                                                        }
                                                    },
                                                    isObservePermissions: isPermissionsContainer,
                                                }}
                                                fieldFormProps={fieldFormProps}
                                            />
                                        </TabContentWrapper>
                                    ),
                                },
                                {
                                    key: "ENTITIES",
                                    disabled: isUserForm || isSubObjectForm || isEnumForm,
                                    label: (
                                        <TabLabel
                                            tabName="entities"
                                            active={current.matches("designer.entities")}
                                            count={current.context.projectConfig.entities?.length}
                                        />
                                    ),
                                    children: (
                                        <TabContentWrapper deductFooter>
                                            <EntitiesTabContent
                                                projectConfig={current.context.projectConfig}
                                                isObserveList={["designer.entities.observeEntitiesList", "designer.entities.deployingVersion"].some(
                                                    current.matches
                                                )}
                                                isEntityForm={isEntityForm}
                                                isFieldForm={current.matches("designer.entities.entityForm.fields.fieldForm")}
                                                isPermissionForm={current.matches("designer.entities.entityForm.permissions.permissionForm")}
                                                sendEntityForm={(entity?: ConfigEntity) => {
                                                    send("ENTITY_FORM", { entity: entity ?? undefined })
                                                }}
                                                sendFieldForm={(field?: EntityField) => {
                                                    send("FIELD_FORM", { field: field ?? undefined })
                                                }}
                                                entityFormProps={{
                                                    entityForm: current.context.entityForm,
                                                    isSavingForm: current.matches("designer.entities.savingConfig"),
                                                    changeEntityName: (entityName: string) => {
                                                        send("CHANGE_ENTITY_NAME", { entityName })
                                                    },
                                                    changeDescription: (description: string) => {
                                                        send("CHANGE_ENTITY_DESCRIPTION", { description })
                                                    },
                                                    sendSaveEntity: () => {
                                                        send("SAVE_ENTITY")
                                                    },
                                                    sendResetEntity: () => {
                                                        send("RESET_ENTITY")
                                                    },
                                                    sendDeleteEntity: () => {
                                                        send("DELETE_ENTITY")
                                                    },
                                                    sendCancelEntity: () => {
                                                        send("CANCEL_ENTITY")
                                                    },
                                                    switchTrackChanges: () => {
                                                        send("SWITCH_TRACK_ENTITY_CHANGES")
                                                    },
                                                    sendObservePermissions: (accessItems?: AccessItem[]) => {
                                                        if (isPermissionsContainer) {
                                                            send("HIDE_PERMISSIONS")
                                                        } else {
                                                            send("OBSERVE_PERMISSIONS", { accessItems })
                                                        }
                                                    },
                                                    isObservePermissions: isPermissionsContainer,
                                                }}
                                                fieldFormProps={fieldFormProps}
                                            />
                                        </TabContentWrapper>
                                    ),
                                },
                                {
                                    key: "SUBOBJECTS",
                                    disabled: isUserForm || isEntityForm || isEnumForm,
                                    label: (
                                        <TabLabel
                                            tabName="subobjects"
                                            active={current.matches("designer.subobjects")}
                                            count={
                                                current.context.projectConfig.subObjects &&
                                                Object.keys(current.context.projectConfig.subObjects).length
                                            }
                                        />
                                    ),
                                    children: (
                                        <TabContentWrapper deductFooter>
                                            <SubObjectsTabContent
                                                projectConfig={current.context.projectConfig}
                                                isSubObjectForm={isSubObjectForm}
                                                isSavingForm={current.matches("designer.subobjects.subObjectForm.savingConfig")}
                                                subObjectForm={current.context.subObjectForm}
                                                sendSubObjectForm={(subObject?: { name: string; fields: EntityField[] }) => {
                                                    send("SUBOBJECT_FORM", { subObject: subObject ?? undefined })
                                                }}
                                                sendSaveSubObjectForm={() => {
                                                    send("SAVE_SUBOBJECT")
                                                }}
                                                sendDeleteSubObject={(key) => {
                                                    send("DELETE_SUBOBJECT", { key })
                                                }}
                                                changeName={(name: string) => {
                                                    send("CHANGE_SUBOBJECT_NAME", { name })
                                                }}
                                                collapseForm={() => {
                                                    send("COLLAPSE_FORM")
                                                }}
                                                isFieldForm={current.matches("designer.subobjects.subObjectForm.fieldForm")}
                                                sendFieldForm={(field?: EntityField, connectionFieldNames?: string[]) => {
                                                    send("FIELD_FORM", {
                                                        field: field ?? undefined,
                                                        connectionFieldNames: connectionFieldNames ?? undefined,
                                                    })
                                                }}
                                                fieldFormProps={fieldFormProps}
                                            />
                                        </TabContentWrapper>
                                    ),
                                },
                                {
                                    key: "ENUMS",
                                    disabled: isUserForm || isEntityForm || isSubObjectForm,
                                    label: (
                                        <TabLabel
                                            tabName="enums"
                                            active={current.matches("designer.enums")}
                                            count={current.context.projectConfig.enums && Object.keys(current.context.projectConfig.enums).length}
                                        />
                                    ),
                                    children: (
                                        <TabContentWrapper deductFooter>
                                            <EnumsTabContent
                                                projectConfig={current.context.projectConfig}
                                                isEnumForm={isEnumForm}
                                                isSavingForm={current.matches("designer.enums.savingConfig")}
                                                enumForm={current.context.enumForm}
                                                sendEnumForm={(enumForm?: { name: string; values: string[] }) => {
                                                    send("ENUM_FORM", { enumForm: enumForm ?? undefined })
                                                }}
                                                sendSaveEnumForm={() => {
                                                    send("SAVE_ENUM")
                                                }}
                                                sendDeleteEnum={(enumKey: string) => {
                                                    send("DELETE_ENUM", { enumKey })
                                                }}
                                                sendCancelEnum={() => {
                                                    send("CANCEL_ENUM")
                                                }}
                                                changeName={(name: string) => {
                                                    send("CHANGE_ENUM_NAME", { name })
                                                }}
                                                changeValues={(values: string[]) => {
                                                    send("CHANGE_ENUM_VALUES", { values })
                                                }}
                                            />
                                        </TabContentWrapper>
                                    ),
                                },
                            ] as TabsProps["items"]
                        }
                    />
                </HalfContainer>

                {isPermissionsContainer && (
                    <HalfContainer deductFooter>
                        <AccessPermissionsContainer
                            entityName={current.context.entityForm.entity.entityName}
                            users={current.context.projectConfig.users}
                            accessItems={current.context.accessItems}
                            isPermissionForm={[
                                "designer.users.userForm.permissions.permissionForm",
                                "designer.entities.entityForm.permissions.permissionForm",
                            ].some(current.matches)}
                            isFieldForm={current.matches("designer.users.userForm.fields.fieldForm")}
                            isInvitable={current.context.userForm?.user.invitable}
                            sendEditAccessPermissionItem={(accessItem?: AccessItem, accessItemIndex?: number) => {
                                send("PERMISSION_FORM", { accessItem, accessItemIndex })
                            }}
                            setInvitable={(groupName: string) => send("SET_INVITABLE", { groupName })}
                            setEmailInvitationUrl={(url: string) => send("SET_EMAIL_INVITATION_URL", { url })}
                            setUserInvitationUrl={(url: string) => send("SET_USER_INVITATION_URL", { url })}
                            accessPermisionFormProps={{
                                userGroups: current.context.projectConfig.users,
                                dataEntities: current.context.projectConfig.entities,
                                accessForm: current.context.accessForm,
                                addCondition: () => {
                                    send("ADD_CONDITION")
                                },
                                setUserGroupName: (groupName: string) => {
                                    send("SET_USER_GROUP_NAME", { groupName })
                                },
                                projectConfig: current.context.projectConfig,
                                userForm: isUserForm ? current.context.userForm : undefined,
                                entityForm: isEntityForm ? current.context.entityForm : undefined,
                                switchRestriction: () => {
                                    send("SWITCH_RESTRICTION")
                                },
                                selectAndConditionObjectField: (fieldName: string, index: number) => {
                                    send("SELECT_ANDCONDITION_OBJECT_FIELD", { fieldName, index })
                                },
                                switchAccessFields: () => {
                                    send("SWITCH_ACCESS_FIELDS")
                                },
                                changeAccessType: () => {
                                    send("CHANGE_ACCESS_TYPE")
                                },
                                sendDeleteAccessPermission: () => {
                                    send("DELETE_ACCESS_PERMISSION")
                                },
                                sendResetAccessermission: () => {
                                    send("RESET_ACCES_PERMISSION")
                                },
                                sendCancelAccessPermission: () => {
                                    send("CANCEL_ACCESS_PERMISSION")
                                },
                                sendSaveAccessPermission: () => {
                                    send("SAVE_ACCESS_PERMISSION")
                                },
                                changeRuleName: (name: string) => {
                                    send("CHANGE_RULE_NAME", { name })
                                },
                                changeDescription: (description: string) => {
                                    send("CHANGE_RULE_DESCRIPTION", { description })
                                },
                                setUserAccessValue: (userField: string, index: number) => {
                                    send("SET_USER_ACCESS_VALUE", { userField, index })
                                },
                                deleteCondition: (index: number) => {
                                    send("DELETE_CONDITION", { index })
                                },
                                changeFieldAccess: (fieldPath: string, accessType: string) => {
                                    send("CHANGE_FIELD_ACCESS", { fieldPath, accessType })
                                },
                            }}
                        />
                    </HalfContainer>
                )}
            </PageWrapper>

            <Footer>
                {/* <Tooltip mouseEnterDelay={0.4} title="Coming soon...">
                    <Button
                        type="primary"
                        icon={<SettingOutlined />}
                        disabled
                        onClick={() => {
                            //
                        }}
                    >
                        Manage versions
                    </Button>
                </Tooltip> */}

                <VersionDetails>
                    <VersionTitle>API version: {current.context.projectConfig.apiVersion}</VersionTitle>
                    <VersionMetadata>
                        {current.context.projectConfig.updatedAt
                            ? `Updated ${dayjs(current.context.projectConfig.updatedAt).format("YYYY/MM/DD HH:mm")}`
                            : "Blank project"}
                        {current.context.project?.deployedAtISOString
                            ? ` | Deployed (v.${current.context.project?.deployedVersion}) ${dayjs(
                                  current.context.project?.deployedAtISOString
                              ).format("YYYY/MM/DD HH:mm")}`
                            : " | Not deployed yet"}
                    </VersionMetadata>
                </VersionDetails>
                {/* TODO: use for deployment errors and schema validation errors
                    <WarningFilled style={{ color: Colors.primary, fontSize: "35px" }} />
                */}
                {isDeployable ? (
                    DeployButton
                ) : (
                    <Tooltip mouseEnterDelay={0.4} title="Finish editing project and save changes before deploying">
                        {DeployButton}
                    </Tooltip>
                )}
            </Footer>
        </>
    )
}

export const TabContentWrapper = styled.div<{ deductFooter?: boolean }>`
    height: calc(100vh - 47px); // ${(props) => (props.deductFooter ? "calc(100vh - 80px - 47px)" : "calc(100vh - 47px)")};
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
`

const Footer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${Spaces.xLarge};
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 20px;
    border-radius: 20px;
    background-color: ${Colors.transparentWhiteBackground};
    box-shadow: ${StyleHelpers.boldBoxShadow};
    color: ${Colors.grayDark};
    z-index: 2;
`

const VersionDetails = styled.div`
    display: flex;
    gap: ${Spaces.small};
    flex-direction: column;
    align-items: start;
`

const VersionTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
`

const VersionMetadata = styled.div`
    font-size: 14px;
    color: ${Colors.grayNormal};
`
