import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useMachine } from "@xstate/react"
import styled from "styled-components"
import { Tabs, Typography, Select, Button, Input, Tooltip, Form } from "antd"
import {
    LockOutlined,
    DeleteOutlined,
    KeyOutlined,
    PlusCircleFilled,
    RollbackOutlined,
    UserOutlined,
    PlayCircleOutlined,
    ReadOutlined,
    LogoutOutlined,
    LoadingOutlined,
    RedoOutlined,
    MailOutlined,
    PlayCircleTwoTone,
    QuestionCircleOutlined,
} from "@ant-design/icons"
import { CopyBlock, github } from "react-code-blocks"

import { TabContentWrapper } from "./Designer"
import { OptionItemWrapper } from "../components/Designer/FieldForm"
import { Selector, SelectorOption } from "../components/Designer/AccessPermissions/AccessPermissionForm"
import {
    Centered,
    StyleHelpers,
    Colors,
    Spaces,
    PageWrapper,
    HalfContainer,
    OneThirdContainer,
    TwoThirdContainer,
    ItemWithFadeInAnimation,
} from "../components/global"
import { SignUpProfile } from "../components/Simulator/SignUpProfile"
import { EndpointItem, EndpointItemWrapper, HeaderWrapper } from "../components/Simulator/EndpointsComponents"
import {
    APIAuthSection,
    SelectedSession,
    AuthSessionsListWrapper,
    MainBlock,
    SideBlock,
    AuthSessionsList,
    AuthSessionItem,
    TextTag,
    NewAuthType,
    LoginSignup,
    EmailVerification,
} from "../components/Simulator/AuthComponents"
import ResponseContainer from "../components/Simulator/ResponseContainer"
import { simulatorMachine } from "../machines/SimulatorMachine"
import { NotificationInstance } from "antd/es/notification/interface"

const { Title } = Typography
const { OptGroup, Option } = Select

export default function SimulatorPage(props: { notificationsApi: NotificationInstance }) {
    const { projectId } = useParams()
    const [current, send] = useMachine(simulatorMachine)

    if (current.context.debug) {
        useEffect(() => {
            const cleanContext: any = { ...current.context }
            delete cleanContext.projectConfig
            delete cleanContext.generatedEndpoints
            console.log(
                `Simulator machine state value changed:\n${JSON.stringify(current.value, null, 4)};\n\n\nWith context:\n${JSON.stringify(
                    cleanContext,
                    null,
                    4
                )}.`
            )
        }, [current.value])
    }

    if (!current.context.notificationsApi) {
        send({ type: "UPDATE_NOTIFICATIONS_API", notificationsApi: props.notificationsApi })
    }

    // TODO: move into context of machine
    const [newAuthType, setNewAuthType] = useState<"USERGROUP" | "APIKEY">("USERGROUP")
    const [newAuthUserGroupName, setNewAuthUserGroupName] = useState<string | undefined>()

    if (current.matches("none")) {
        if (projectId) {
            send({ type: "FETCH", projectId: projectId })
            return <></>
        } else {
            return (
                <PageWrapper>
                    <Centered vertical>
                        <Title level={2}>Missing project ID in URL...</Title>
                    </Centered>
                </PageWrapper>
            )
        }
    }

    if (current.matches("globalLoading"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <LoadingOutlined style={{ fontSize: "75px", color: Colors.primary }} />
                    <Title level={2}>Loading project endpoints...</Title>
                </Centered>
            </PageWrapper>
        )

    if (current.matches("error"))
        return (
            <PageWrapper>
                <Centered vertical>
                    <Title level={2}>Error loading project endpoints: try again...</Title>
                    <Button
                        type="primary"
                        icon={<RedoOutlined />}
                        onClick={() => {
                            send("TRY_AGAIN")
                        }}
                    >
                        Load project endpoints
                    </Button>
                </Centered>
            </PageWrapper>
        )

    const sideBlockStatesArr = [
        "simulatorWrapper.userSession.selectAuthType",
        "simulatorWrapper.userSession.login",
        "simulatorWrapper.userSession.logingIn",
        "simulatorWrapper.userSession.signup",
        "simulatorWrapper.userSession.signupProfile",
        "simulatorWrapper.userSession.creatingUser",
        "simulatorWrapper.userSession.verifyEmail",
        "simulatorWrapper.userSession.verifyingEmail",
    ]

    const isSignupState = ["simulatorWrapper.userSession.signup"].some(current.matches)
    const isLoginState = ["simulatorWrapper.userSession.login", "simulatorWrapper.userSession.logingIn"].some(current.matches)

    const selectedSignupUserGroup = current.context.projectConfig?.users.find((u) => u.groupName == current.context.apiAuth.userGroup)

    let allowedFields: string[] | undefined
    if (current.matches("simulatorWrapper.userSession.signupProfile")) {
        const createMutation = current.context.generatedEndpoints.mutations.create.find((m) => m.name == "create" + current.context.apiAuth.userGroup)
        if (createMutation) {
            const createEv = createMutation?.endpointVariants.find((ev) => ev.accessItem.userGroupName == current.context.apiAuth.userGroup)
            if (createEv) {
                allowedFields = createEv.inputFields
            }
        }
    }

    return (
        <Tabs
            defaultActiveKey="simulator"
            centered
            onChange={(key) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                send(key)
            }}
            items={[
                {
                    key: "SIMULATOR",
                    label: (
                        <span>
                            <PlayCircleOutlined style={{ marginRight: "5px", marginTop: "5px" }} />
                            Live API
                        </span>
                    ),
                    children: (
                        <ApiSimulatorTabWrapper>
                            <APIAuthSection>
                                <Title level={2} style={{ marginTop: "12px", marginBottom: Spaces.medium }}>
                                    API authentication
                                </Title>

                                {current.matches("simulatorWrapper.userSession.selectedSession") && (
                                    <SelectedSession>
                                        <UserOutlined style={{ fontSize: StyleHelpers.largeIconSize }} />
                                        <Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
                                            {current.context.apiAuth.currentAuthSession?.username}
                                        </Title>
                                        <TextTag>{current.context.apiAuth.currentAuthSession?.userGroup}</TextTag>
                                        <Button type="primary" icon={<LogoutOutlined />} onClick={() => send("LOGOUT")}>
                                            Logout
                                        </Button>
                                    </SelectedSession>
                                )}

                                {["simulatorWrapper.userSession.activeSessionsList", ...sideBlockStatesArr].some(current.matches) && (
                                    <AuthSessionsListWrapper>
                                        <MainBlock>
                                            <Title level={4} style={{ marginTop: 0, marginBottom: Spaces.normal }}>
                                                Active sessions
                                            </Title>
                                            <AuthSessionsList>
                                                {current.context.apiAuth.authSessionsList.length === 0 && (
                                                    <Title level={5} style={{ marginTop: 0, marginBottom: Spaces.normal }}>
                                                        No sessions yet...
                                                    </Title>
                                                )}
                                                {current.context.apiAuth.authSessionsList.map((s, ind) => {
                                                    return (
                                                        <AuthSessionItem
                                                            index={ind}
                                                            clickable={current.matches("simulatorWrapper.userSession.activeSessionsList")}
                                                            onClick={() => send({ type: "SELECT_SESSION", sessionIndex: ind })}
                                                            key={ind}
                                                        >
                                                            {s.authType == "USERGROUP" ? (
                                                                <UserOutlined style={{ fontSize: StyleHelpers.iconSize }} />
                                                            ) : (
                                                                <KeyOutlined style={{ fontSize: StyleHelpers.iconSize }} />
                                                            )}
                                                            <span className="name">{s.username}</span>
                                                            {s.authType == "USERGROUP" && <TextTag>{s.userGroup}</TextTag>}
                                                            <div>
                                                                <Button
                                                                    disabled={!current.matches("simulatorWrapper.userSession.activeSessionsList")}
                                                                    type="primary"
                                                                    shape="circle"
                                                                    icon={<DeleteOutlined />}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        send({ type: "DELETE_SESSION", sessionIndex: ind })
                                                                    }}
                                                                />
                                                            </div>
                                                        </AuthSessionItem>
                                                    )
                                                })}
                                            </AuthSessionsList>
                                            <Centered>
                                                {!current.matches("simulatorWrapper.userSession.activeSessionsList") ? (
                                                    <Button type="primary" icon={<RollbackOutlined />} onClick={() => send("BACK_TO_SESSIONS")}>
                                                        Cancel
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        // disabled={!current.matches("simulatorWrapper.userSession.activeSessionsList")}
                                                        type="primary"
                                                        icon={<PlusCircleFilled />}
                                                        onClick={() => send("NEW_SESSION")}
                                                    >
                                                        Add auth session
                                                    </Button>
                                                )}
                                            </Centered>
                                        </MainBlock>

                                        {/* {sideBlockStatesArr.some(current.matches) && <VerticalLine />} */}

                                        {sideBlockStatesArr.some(current.matches) && (
                                            <SideBlock>
                                                {current.matches("simulatorWrapper.userSession.selectAuthType") && (
                                                    <NewAuthType reversed>
                                                        <Selector
                                                            onClick={() => {
                                                                // setNewAuthType(newAuthType == "USERGROUP" ? "APIKEY" : "USERGROUP")
                                                            }}
                                                        >
                                                            <SelectorOption selected={newAuthType === "USERGROUP"}>
                                                                <UserOutlined /> User group
                                                            </SelectorOption>
                                                            {/* <SelectorOption selected={newAuthType === "APIKEY"}>
                                                                <KeyOutlined /> API-key
                                                            </SelectorOption> */}
                                                        </Selector>
                                                        {newAuthType === "USERGROUP" ? (
                                                            <Select
                                                                placeholder="Select user group name..."
                                                                title="Select user group name..."
                                                                style={{ width: 225 }}
                                                                allowClear
                                                                value={newAuthUserGroupName}
                                                                onSelect={(value) => {
                                                                    setNewAuthUserGroupName(value)
                                                                }}
                                                            >
                                                                <OptGroup label="User group" key="user group">
                                                                    {Object.keys(current.context.projectConfig.users).map((un: string) => {
                                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                                        // @ts-ignore
                                                                        const u = current.context.projectConfig.users[un]
                                                                        return (
                                                                            <Option value={u.groupName} key={u.groupName}>
                                                                                <OptionItemWrapper>
                                                                                    <UserOutlined
                                                                                        style={{
                                                                                            width: StyleHelpers.iconSize,
                                                                                            color: Colors.grayDark,
                                                                                        }}
                                                                                    />
                                                                                    {u.groupName}
                                                                                </OptionItemWrapper>
                                                                            </Option>
                                                                        )
                                                                    })}
                                                                </OptGroup>
                                                            </Select>
                                                        ) : (
                                                            // TODO: implement API key dropdown (irrelevant because of default ADMIN user group covers this scenario)
                                                            <div>API key drop down goes here...</div>
                                                            // <Select
                                                            //     placeholder="Select API key..."
                                                            //     style={{ width: 225 }}
                                                            //     allowClear
                                                            //     value={apiKeyName}
                                                            //     onSelect={(value) => {
                                                            //         setApiKeyName(value)
                                                            //     }}
                                                            // >
                                                            //     <OptGroup label="User group" key="user group">
                                                            //         {apiKeys.map((e: User) => (
                                                            //             <Option value={e.name} key={e.name}>
                                                            //                 <OptionItemWrapper>
                                                            //                     <UserOutlined
                                                            //                         style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }}
                                                            //                     />
                                                            //                     {e.name}
                                                            //                 </OptionItemWrapper>
                                                            //             </Option>
                                                            //         ))}
                                                            //     </OptGroup>
                                                            // </Select>
                                                        )}
                                                        <Button
                                                            type="primary"
                                                            disabled={!newAuthUserGroupName || newAuthType === "APIKEY"}
                                                            onClick={() => {
                                                                if (newAuthType === "USERGROUP") {
                                                                    send({
                                                                        type: "SELECT_USER",
                                                                        userGroup: newAuthUserGroupName!,
                                                                    })
                                                                } else if (newAuthType === "APIKEY") {
                                                                    // FIXME: implement API key logic
                                                                    send({ type: "SELECT_API", apikey: "" })
                                                                }
                                                            }}
                                                        >
                                                            Next
                                                        </Button>
                                                    </NewAuthType>
                                                )}

                                                {(isLoginState || isSignupState) && (
                                                    <LoginSignup reversed>
                                                        <Selector
                                                            onClick={() => {
                                                                if (isLoginState) {
                                                                    send("SIGNUP_FORM")
                                                                } else {
                                                                    send("LOGIN_FORM")
                                                                }
                                                            }}
                                                        >
                                                            <SelectorOption selected={isLoginState}>
                                                                <UserOutlined /> Login
                                                            </SelectorOption>
                                                            <SelectorOption selected={isSignupState}>
                                                                <KeyOutlined /> Sign up
                                                            </SelectorOption>
                                                        </Selector>
                                                        <Form
                                                            onFinish={() => {
                                                                if (isLoginState) {
                                                                    send("LOGIN_CALL")
                                                                } else {
                                                                    send("SIGNUP_PROFILE")
                                                                }
                                                            }}
                                                            autoComplete="off"
                                                        >
                                                            <Form.Item
                                                                name="username"
                                                                rules={[{ required: true, message: "Please input your email address!" }]}
                                                            >
                                                                <Input
                                                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                                                    placeholder="User email address"
                                                                    title="User email address"
                                                                    size="large"
                                                                    style={{ width: "300px" }}
                                                                    value={
                                                                        isLoginState
                                                                            ? current.context.apiAuth.loginForm.username
                                                                            : current.context.apiAuth.signupForm.username
                                                                    }
                                                                    autoFocus
                                                                    onChange={(e) => {
                                                                        send({ type: "SET_USERNAME", username: e.target.value })
                                                                    }}
                                                                />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name="password"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please input your password!",
                                                                    },
                                                                    {
                                                                        pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*\W).+$/,
                                                                        message: (
                                                                            <>
                                                                                Should contain at least 1 uppercase,
                                                                                <br />1 lowercase, 1 digit and 1 special charecter.
                                                                            </>
                                                                        ),
                                                                    },
                                                                    {
                                                                        min: 8,
                                                                        message: "Should be at least 8 characters.",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input.Password
                                                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                                                    placeholder="User password"
                                                                    title="User password"
                                                                    size="large"
                                                                    style={{ width: "300px" }}
                                                                    value={
                                                                        isLoginState
                                                                            ? current.context.apiAuth.loginForm.password
                                                                            : current.context.apiAuth.signupForm.password
                                                                    }
                                                                    onChange={(e) => {
                                                                        send({ type: "SET_PASSWORD", password: e.target.value })
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                            {isSignupState && selectedSignupUserGroup?.invitable && (
                                                                <Form.Item>
                                                                    <Input
                                                                        placeholder="Invitation token"
                                                                        title="Invitation token"
                                                                        size="large"
                                                                        style={{ width: "300px" }}
                                                                        value={current.context.apiAuth.signupForm.invitationCode}
                                                                        onChange={(e) => {
                                                                            send({
                                                                                type: "SET_INVITATION_TOKEN",
                                                                                invitationCode: e.target.value,
                                                                            })
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            )}
                                                            <Button
                                                                type="primary"
                                                                htmlType="submit"
                                                                loading={["simulatorWrapper.userSession.logingIn"].some(current.matches)}
                                                            >
                                                                {isLoginState ? "Login" : "Sign up"}
                                                            </Button>
                                                        </Form>
                                                    </LoginSignup>
                                                )}

                                                {["simulatorWrapper.userSession.signupProfile", "simulatorWrapper.userSession.creatingUser"].some(
                                                    current.matches
                                                ) && (
                                                    <SignUpProfile
                                                        sendSignUp={() => {
                                                            send("FINISH_SIGNUP")
                                                        }}
                                                        sendProfile={(profile) => {
                                                            send({ type: "SET_PROFILE", profile: profile })
                                                        }}
                                                        isCreating={current.matches("simulatorWrapper.userSession.creatingUser")}
                                                        title={current.context.apiAuth.userGroup}
                                                        projectConfig={current.context.projectConfig}
                                                        allowedFields={allowedFields}
                                                        userFields={
                                                            current.context.projectConfig.users.find(
                                                                (u) => u.groupName === current.context.apiAuth.userGroup
                                                            )!.userData.fields
                                                        }
                                                    />
                                                )}

                                                {["simulatorWrapper.userSession.verifyEmail", "simulatorWrapper.userSession.verifyingEmail"].some(
                                                    current.matches
                                                ) && (
                                                    <EmailVerification>
                                                        <Input
                                                            prefix={<MailOutlined className="site-form-item-icon" />}
                                                            placeholder="Email verification code..."
                                                            title="Email verification code..."
                                                            size="large"
                                                            style={{ width: "300px" }}
                                                            value={current.context.apiAuth.signupForm.emailCode}
                                                            onChange={(e) => {
                                                                send({ type: "SET_CODE", code: e.target.value })
                                                            }}
                                                        />
                                                        <Button
                                                            type="primary"
                                                            loading={["simulatorWrapper.userSession.verifyingEmail"].some(current.matches)}
                                                            disabled={current.context.apiAuth.signupForm.emailCode === ""}
                                                            onClick={() => {
                                                                send("VERIFY_EMAIL")
                                                            }}
                                                        >
                                                            Verify email
                                                        </Button>
                                                    </EmailVerification>
                                                )}
                                            </SideBlock>
                                        )}
                                    </AuthSessionsListWrapper>
                                )}
                            </APIAuthSection>

                            <Containers>
                                <EndpointsContainer shadow={!current.matches("simulatorWrapper.response.hidden")}>
                                    <GQLSimulatorHeader>
                                        <Title level={3} style={{ marginTop: 0, marginBottom: 0 }}>
                                            Live API
                                        </Title>
                                        {/* FIXME: after implement version control */}
                                        {/* <Select
                                            //value={current.context.gqlSimulator.version}
                                            disabled
                                            placeholder="Select version..."
                                        /> */}
                                        {/* FIXME: add environments => into code constructor [Tasks] */}
                                        <Select
                                            //value={current.context.gqlSimulator.env}
                                            disabled
                                            placeholder="Select environment..."
                                            title="Select environment..."
                                        />
                                    </GQLSimulatorHeader>

                                    {(current.context.gqlSimulator.visibleInvitationUserGroups?.length !== 0 ||
                                        current.context.apiAuth.currentAuthSession == undefined) && (
                                        <Title level={4} style={{ marginTop: 0, marginBottom: Spaces.normal }}>
                                            Invitations
                                        </Title>
                                    )}
                                    {(current.context.gqlSimulator.visibleInvitationUserGroups?.length !== 0 ||
                                        current.context.apiAuth.currentAuthSession == undefined) && (
                                        <EndpointItemWrapper index={0}>
                                            <HeaderWrapper>
                                                <Tooltip
                                                    mouseEnterDelay={0.4}
                                                    title="Invite new user over email to sign up and fulfil profile details. Or invite existing user group profile to finish sign up."
                                                    overlayInnerStyle={{ width: "300px" }}
                                                >
                                                    <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                                                        Invite
                                                    </Title>
                                                </Tooltip>

                                                <Select
                                                    placeholder="Select User group..."
                                                    title="Select User group..."
                                                    style={{ width: 180 }}
                                                    allowClear
                                                    disabled={current.context.gqlSimulator.visibleInvitationUserGroups?.length == 0}
                                                    value={current.context.gqlSimulator.invitationEndpoint?.userGroup}
                                                    onSelect={(userGroup) => {
                                                        send({ type: "SET_INVITATION_USER_GROUP", userGroup })
                                                    }}
                                                >
                                                    {current.context.gqlSimulator.visibleInvitationUserGroups?.map((ug) => {
                                                        return (
                                                            <Option value={ug} key={ug}>
                                                                <OptionItemWrapper>{`${ug}`}</OptionItemWrapper>
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                <Input
                                                    placeholder="Email address"
                                                    title="Email address"
                                                    style={{ width: "unset" }}
                                                    disabled={current.context.gqlSimulator.visibleInvitationUserGroups?.length == 0}
                                                    value={current.context.gqlSimulator.invitationEndpoint?.email}
                                                    onChange={(e) => send({ type: "SET_INVITATION_EMAIL", email: e.target.value })}
                                                />
                                                {/* <Button
                                                    type="text"
                                                    icon={
                                                        <Tooltip mouseEnterDelay={0.4} mouseLeaveDelay={0} title={"Code sample"}>
                                                            <CodeOutlined
                                                                style={{
                                                                    fontSize: StyleHelpers.largeIconSize,
                                                                    color: Colors.grayDark,
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    // onClick={(e) => openCodeSample } TODO: maybe implement here REST API code block with invitation payload?
                                                /> */}
                                                <Button
                                                    type="text"
                                                    loading={current.matches("simulatorWrapper.gqlSimulator.sendingInvitation")}
                                                    disabled={
                                                        current.context.gqlSimulator.visibleInvitationUserGroups?.length == 0 ||
                                                        !current.context.gqlSimulator.invitationEndpoint?.email ||
                                                        !current.context.gqlSimulator.invitationEndpoint?.userGroup
                                                    }
                                                    icon={
                                                        <PlayCircleTwoTone
                                                            style={{ fontSize: StyleHelpers.largeIconSize }}
                                                            twoToneColor={
                                                                current.context.gqlSimulator.visibleInvitationUserGroups?.length == 0 ||
                                                                !current.context.gqlSimulator.invitationEndpoint?.email ||
                                                                !current.context.gqlSimulator.invitationEndpoint?.userGroup
                                                                    ? Colors.grayDark
                                                                    : Colors.primary
                                                            }
                                                        />
                                                    }
                                                    onClick={() => send("SEND_INVITATION")}
                                                />
                                            </HeaderWrapper>
                                        </EndpointItemWrapper>
                                    )}
                                    {current.context.apiAuth.currentAuthSession == undefined && (
                                        <EndpointItemWrapper index={0}>
                                            <HeaderWrapper>
                                                <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                                                    {"Accept "}
                                                    <Tooltip
                                                        title="In case user profile already exists accept invitation to finish sign up. Otherwise use signup from above with invitation code to submit new profile details."
                                                        overlayInnerStyle={{ width: "300px" }}
                                                    >
                                                        <QuestionCircleOutlined />
                                                    </Tooltip>
                                                </Title>

                                                <Input
                                                    placeholder="Invitation code"
                                                    title="Invitation code"
                                                    style={{ width: "unset" }}
                                                    value={current.context.gqlSimulator.acceptInvitationEndpoint?.invitationCode}
                                                    onChange={(e) => send({ type: "SET_ACCEPT_INVITATION_CODE", invitationCode: e.target.value })}
                                                />
                                                <Input
                                                    placeholder="Email address"
                                                    title="Email address"
                                                    style={{ width: "unset" }}
                                                    value={current.context.gqlSimulator.acceptInvitationEndpoint?.email}
                                                    onChange={(e) => send({ type: "SET_ACCEPT_INVITATION_EMAIL", email: e.target.value })}
                                                />
                                                <Input
                                                    type="password"
                                                    placeholder="New password"
                                                    title="New password"
                                                    style={{ width: "unset" }}
                                                    value={current.context.gqlSimulator.acceptInvitationEndpoint?.password}
                                                    onChange={(e) => send({ type: "SET_ACCEPT_INVITATION_PASSWORD", password: e.target.value })}
                                                />
                                                <Button
                                                    type="text"
                                                    loading={current.matches("simulatorWrapper.gqlSimulator.acceptingInvitation")}
                                                    disabled={
                                                        !current.context.gqlSimulator.acceptInvitationEndpoint?.email ||
                                                        !current.context.gqlSimulator.acceptInvitationEndpoint?.invitationCode ||
                                                        !current.context.gqlSimulator.acceptInvitationEndpoint?.password
                                                    }
                                                    icon={
                                                        <PlayCircleTwoTone
                                                            style={{ fontSize: StyleHelpers.largeIconSize }}
                                                            twoToneColor={
                                                                !current.context.gqlSimulator.acceptInvitationEndpoint?.email ||
                                                                !current.context.gqlSimulator.acceptInvitationEndpoint?.invitationCode ||
                                                                !current.context.gqlSimulator.acceptInvitationEndpoint?.password
                                                                    ? Colors.grayDark
                                                                    : Colors.primary
                                                            }
                                                        />
                                                    }
                                                    onClick={() => {
                                                        send("SEND_ACCEPT_INVITATION")
                                                    }}
                                                />
                                            </HeaderWrapper>
                                        </EndpointItemWrapper>
                                    )}

                                    <Title level={4} style={{ marginTop: 0, marginBottom: Spaces.normal }}>
                                        Queries
                                    </Title>

                                    {current.context.gqlSimulator.visibleQueryEndpoints?.map((e, ind) => {
                                        return (
                                            <EndpointItem
                                                index={ind}
                                                key={e.name}
                                                type={e.type}
                                                entityDescription={e.description}
                                                titleName={e.name}
                                                isInputOutputAll={e.isInputOutputAll}
                                                outputFields={e.outputFields}
                                                projectConfig={current.context.projectConfig}
                                                entityName={e.entityName}
                                                outputSubfields={e.outputSubfields}
                                                openFilter={() => {
                                                    send({ type: "OPEN_FILTER", endpointName: e.name })
                                                }}
                                                openSelectorFields={() => {
                                                    send({ type: "OPEN_SELECTOR_FIELDS", endpointName: e.name })
                                                }}
                                                openCodeSample={() => {
                                                    send({ type: "OPEN_CODE_SAMPLE", endpointName: e.name })
                                                }}
                                                selectEndpoint={() => {
                                                    send({ type: "SELECT_ENDPOINT", endpointName: e.name })
                                                }}
                                                closeEndpoint={() => {
                                                    send("CLOSE_ENDPOINT")
                                                }}
                                                collapseAll={() => {
                                                    send("COLLAPSE_ALL")
                                                }}
                                                run={() => {
                                                    send({ type: "RUN", endpointName: e.name })
                                                }}
                                                isAuthenticated={current.context.apiAuth.currentAuthSession !== undefined}
                                                isSelected={current.context.gqlSimulator.selectedEndpoint === e.name}
                                                isFilter={current.matches("simulatorWrapper.gqlSimulator.queryFilter")}
                                                isSelectorFields={current.matches("simulatorWrapper.gqlSimulator.querySelectorFields")}
                                                isCodeSample={current.matches("simulatorWrapper.gqlSimulator.codeSample")}
                                                selectField={(pathKey: string) => {
                                                    send({ type: "SELECT_FIELD", field: pathKey })
                                                }}
                                                unselectField={(pathKey: string) => {
                                                    send({ type: "UNSELECT_FIELD", field: pathKey })
                                                }}
                                                filterRules={{
                                                    filters: current.context.gqlSimulator.endpointsData[e.name]?.filters,
                                                    addFilterRule: () => {
                                                        send({ type: "ADD_RULE" })
                                                    },
                                                    removeFilterRule: (ruleIndex: number) => {
                                                        send({ type: "REMOVE_RULE", ruleIndex })
                                                    },
                                                    changeFilterField: (ruleIndex: number, field: string) => {
                                                        send({ type: "CHANGE_RULE_FIELD", ruleIndex, field })
                                                    },
                                                    changeFilterOperator: (ruleIndex: number, operator: string) => {
                                                        send({ type: "CHANGE_OPERATOR", ruleIndex, operator })
                                                    },
                                                    changeFilterValue: (ruleIndex: number, value: string) => {
                                                        send({ type: "CHANGE_VALUE", ruleIndex, value })
                                                    },
                                                }}
                                                endpointData={current.context.gqlSimulator.endpointsData[e.name]}
                                                setObjectId={(objectId: string) => send({ type: "SET_OBJECTID", endpointName: e.name, objectId })}
                                            />
                                        )
                                    })}

                                    <Title level={4} style={{ marginTop: Spaces.xLarge, marginBottom: Spaces.normal }}>
                                        Mutations
                                    </Title>

                                    {current.context.gqlSimulator.visibleMutationEndpoints?.map((e, ind) => {
                                        let index = current.context.gqlSimulator.visibleQueryEndpoints?.length ?? 0
                                        index += ind
                                        return (
                                            <EndpointItem
                                                index={index}
                                                key={e.name}
                                                type={e.type}
                                                entityDescription={e.description}
                                                titleName={e.name}
                                                isInputOutputAll={e.isInputOutputAll}
                                                outputFields={e.outputFields}
                                                projectConfig={current.context.projectConfig}
                                                entityName={e.entityName}
                                                outputSubfields={e.outputSubfields}
                                                inputFields={e.inputFields}
                                                openSelectorFields={() => {
                                                    send({ type: "OPEN_SELECTOR_FIELDS", endpointName: e.name })
                                                }}
                                                openCodeSample={() => {
                                                    send({ type: "OPEN_CODE_SAMPLE", endpointName: e.name })
                                                }}
                                                openMutationForm={() => {
                                                    send({ type: "OPEN_MUTATION_FORM", endpointName: e.name })
                                                }}
                                                setMutationFormValues={(values: any) => {
                                                    send({ type: "SET_MUTATION_FORM_FIELDS_VALUES", values: values })
                                                }}
                                                mutationFormValues={current.context.gqlSimulator.endpointsData[e.name]?.inputs}
                                                selectEndpoint={() => {
                                                    send({ type: "SELECT_ENDPOINT", endpointName: e.name })
                                                }}
                                                closeEndpoint={() => {
                                                    send("CLOSE_ENDPOINT")
                                                }}
                                                collapseAll={() => {
                                                    send("COLLAPSE_ALL")
                                                }}
                                                run={() => {
                                                    send({ type: "RUN", endpointName: e.name })
                                                }}
                                                isAuthenticated={current.context.apiAuth.currentAuthSession !== undefined}
                                                isSelected={current.context.gqlSimulator.selectedEndpoint === e.name}
                                                isSelectorFields={current.matches("simulatorWrapper.gqlSimulator.querySelectorFields")}
                                                isCodeSample={current.matches("simulatorWrapper.gqlSimulator.codeSample")}
                                                isMutationForm={current.matches("simulatorWrapper.gqlSimulator.mutationForm")}
                                                selectField={(pathKey: string) => {
                                                    send({ type: "SELECT_FIELD", field: pathKey })
                                                }}
                                                unselectField={(pathKey: string) => {
                                                    send({ type: "UNSELECT_FIELD", field: pathKey })
                                                }}
                                                endpointData={current.context.gqlSimulator.endpointsData[e.name]}
                                                setObjectId={(objectId: string) => send({ type: "SET_OBJECTID", endpointName: e.name, objectId })}
                                            />
                                        )
                                    })}

                                    {/* FIXME: implement subscription endpoints
                                        reuse EndpointItem
                                        with filters and selectors 
                                        add total received events + new unread events counter
                                        icon to listen -> then tick icon and pause/stop on hover
                                        <RetweetOutlined />
                                        <CheckCircleOutlined />
                                        <PauseCircleOutlined />
                                    */}
                                </EndpointsContainer>

                                <HalfContainerResponse>
                                    {!current.matches("simulatorWrapper.response.hidden") ? (
                                        <ResponseContainer
                                            isLoading={
                                                current.context.gqlSimulator.endpointsData[current.context.gqlSimulator.selectedEndpoint]?.status ===
                                                "loading"
                                            }
                                            response={
                                                current.context.gqlSimulator.endpointsData[current.context.gqlSimulator.selectedEndpoint]?.response
                                            }
                                            type={
                                                current.context.gqlSimulator.selectedEndpoint.startsWith("get")
                                                    ? "Get"
                                                    : current.context.gqlSimulator.selectedEndpoint.startsWith("list")
                                                      ? "List"
                                                      : ""
                                            }
                                        />
                                    ) : (
                                        <Title level={5} style={{ padding: `0 ${Spaces.large}` }}>
                                            Run endpoint to see API response...
                                        </Title>
                                    )}
                                </HalfContainerResponse>
                            </Containers>
                        </ApiSimulatorTabWrapper>
                    ),
                },
                {
                    key: "DOCS",
                    label: (
                        <span>
                            <ReadOutlined style={{ marginRight: "5px", marginTop: "5px" }} />
                            Tech Docs
                        </span>
                    ),
                    children: (
                        <ApiDocsTabWrapper>
                            <TwoThirdContainer style={{ height: "calc(100vh - 50px)", backgroundColor: "white", order: 2, padding: "0 0 0 20px" }}>
                                <CopyBlock
                                    text={current.context.clientTypes}
                                    language="graphql"
                                    showLineNumbers={false}
                                    wrapLongLines
                                    theme={github}
                                />
                            </TwoThirdContainer>
                            <OneThirdContainer style={{ height: "calc(100vh - 50px)", order: 1, padding: "0" }}>
                                <SideMenuWrapper>
                                    <SideMenuItem index={1} selected>
                                        Client-side TypeScript types
                                    </SideMenuItem>
                                    {/* TODO: implement more */}
                                    <SideMenuItem index={1} disabled>
                                        GraphQL API queries samples
                                    </SideMenuItem>
                                    <SideMenuItem index={1} disabled>
                                        SDK guide
                                    </SideMenuItem>
                                </SideMenuWrapper>
                            </OneThirdContainer>
                        </ApiDocsTabWrapper>
                    ),
                },
            ]}
        />
    )
}

const HalfContainerResponse = styled(HalfContainer)`
    &:last-child {
        padding: 0;
    }
`

const ApiSimulatorTabWrapper = styled(TabContentWrapper)`
    overflow-y: clip;
    padding-top: 0px;
`

const ApiDocsTabWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 0;
    overflow-y: scroll;
    width: 100vw;
    height: calc(100vh - 50px);
    background-color: ${Colors.background};
`

const Containers = styled.div`
    display: flex;
    flex-direction: row;
    align-self: stretch;
    flex-grow: 1;
    overflow-y: scroll;
`

const EndpointsContainer = styled(HalfContainer)<{ shadow: boolean }>`
    box-shadow: ${({ shadow }) => (shadow ? StyleHelpers.boldBoxShadow : "none")};
    background-color: ${Colors.background};
    padding: ${Spaces.medium} ${Spaces.large} 80px;
`

const GQLSimulatorHeader = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${Spaces.medium};
    margin-bottom: ${Spaces.medium};
`

const SideMenuWrapper = styled.div`
    display: flex;
    padding: ${Spaces.medium} ${Spaces.normal};
    flex-direction: column;
`

const SideMenuItem = styled(ItemWithFadeInAnimation)<{ index: number; selected?: boolean; disabled?: boolean }>`
    width: 100%;
    border-radius: ${StyleHelpers.radiusSmall};
    padding: ${Spaces.normal};
    margin-bottom: ${Spaces.normal};
    background-color: white;
    border: ${(props) => (props.selected ? `2px solid ${Colors.primary}` : "unset")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-weight: 600;
    color: ${(props) => (props.disabled ? Colors.grayNormal : Colors.primary)};

    animation-delay: ${(props: { index: number }) => props.index * 0.15}s; /* delay animation start for each item */

    box-shadow: ${StyleHelpers.staticBoxShadow};

    :hover {
        box-shadow: ${(props) => (props.selected ? "unset" : props.disabled ? StyleHelpers.staticBoxShadow : StyleHelpers.lightBoxShadow)};
        transform: ${(props) => (props.selected || props.disabled ? "unset" : "scale(1.01)")};
    }
`
