import React, { useState } from "react"
import styled from "styled-components"
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Select, Switch, FormInstance, Typography } from "antd"
import { DeleteOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons"

import type { EntityField, Config } from "../../coreTypes/config"
import { ScrollableBlock, Spaces, Colors, StyleHelpers } from "../global"
import { getTypeIcon } from "../Designer/DataField"

const { Option } = Select
const { Title } = Typography

function ProcessFieldInput(props: {
    projectConfig: Config
    field: EntityField
    parentPath?: string
    ind?: number
    deleteLastAdditionalItem?: () => void
    form?: FormInstance
}): JSX.Element {
    const { projectConfig, field, parentPath, ind, deleteLastAdditionalItem, form } = props
    const fullFieldName = parentPath ? parentPath + "." + field.name : field.name + (field.isArray ? (ind ? `.${ind}` : ".0") : "")
    // console.log(fullFieldName)

    const deleteButton = deleteLastAdditionalItem ? <Button type="text" danger onClick={deleteLastAdditionalItem} icon={<DeleteOutlined />} /> : null

    let formItem: JSX.Element = <></>
    switch (field.type) {
        case "STRING":
        case "USER_ID":
        case "OBJECT_ID":
        case "EMAIL":
        case "PHONENUMBER":
            formItem = (
                <>
                    <FormItem
                        label={
                            <FormItemLabel>
                                {getTypeIcon(field.type)}
                                {field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}
                            </FormItemLabel>
                        }
                        name={fullFieldName}
                        key={fullFieldName}
                        tooltip={field.description}
                        rules={[
                            {
                                required: field.mandatory,
                            },
                        ]}
                        // FIXME: validator for USER_ID OBJECT_ID EMAIL PHONENUMBER [Tasks]
                        // {
                        //     validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error("Should accept agreement"))),
                        // },
                    >
                        <Input placeholder={field.defaultValue ? "default: " + field.defaultValue : ""} />
                    </FormItem>
                    {deleteButton && <Form.Item label=" ">{deleteButton}</Form.Item>}
                </>
            )
            break
        case "INT":
        case "FLOAT":
        case "DOUBLE":
            formItem = (
                <>
                    <FormItem
                        label={
                            <FormItemLabel>
                                {getTypeIcon(field.type)}
                                {field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}
                            </FormItemLabel>
                        }
                        name={fullFieldName}
                        key={fullFieldName}
                        tooltip={field.description}
                        rules={[
                            {
                                required: field.mandatory,
                            },
                        ]}
                    >
                        <InputNumber
                            placeholder={field.defaultValue ? "default: " + field.defaultValue : ""}
                            // FIXME: apply Int, Float, Double formatting
                            // form.getFieldValue -> form.setFieldValue
                        />
                    </FormItem>
                    {deleteButton && <Form.Item label=" ">{deleteButton}</Form.Item>}
                </>
            )
            break
        case "BOOLEAN":
            formItem = (
                <>
                    <FormItem
                        label={
                            <FormItemLabel>
                                {getTypeIcon(field.type)}
                                {field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}
                            </FormItemLabel>
                        }
                        valuePropName="checked"
                        name={fullFieldName}
                        key={fullFieldName}
                        tooltip={field.description}
                        rules={[
                            {
                                required: field.mandatory,
                            },
                        ]}
                    >
                        <Checkbox>{field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}</Checkbox>
                    </FormItem>
                    {deleteButton && <Form.Item label=" ">{deleteButton}</Form.Item>}
                </>
            )
            break
        case "DATE":
        case "TIME":
            formItem = (
                <>
                    <FormItem
                        label={
                            <FormItemLabel>
                                {getTypeIcon(field.type)}
                                {field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}
                            </FormItemLabel>
                        }
                        name={fullFieldName}
                        key={fullFieldName}
                        tooltip={field.description}
                        rules={[
                            {
                                required: field.mandatory,
                            },
                        ]}
                    >
                        <DatePicker showTime={field.type === "TIME"} placeholder={field.defaultValue ? "default: " + field.defaultValue : ""} />
                    </FormItem>
                    {deleteButton && <Form.Item label=" ">{deleteButton}</Form.Item>}
                </>
            )
            break
        case "ENUM":
            formItem = (
                <>
                    <FormItem
                        label={
                            <FormItemLabel>
                                {getTypeIcon(field.type)}
                                {field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}
                            </FormItemLabel>
                        }
                        name={fullFieldName}
                        key={fullFieldName}
                        tooltip={field.description}
                        rules={[
                            {
                                required: field.mandatory,
                            },
                        ]}
                    >
                        <Select allowClear placeholder={field.defaultValue ? "default: " + field.defaultValue : ""}>
                            {projectConfig.enums![field.enumName!].map((val) => (
                                <Option value={val} key={val}>
                                    {val}
                                </Option>
                            ))}
                        </Select>
                    </FormItem>
                    {deleteButton && <Form.Item label=" ">{deleteButton}</Form.Item>}
                </>
            )
            break
        case "SUBOBJECT":
            const [showSubObject, setShowSubobject] = useState(false)

            formItem = (
                <SubObjectFormItemWrapper key={fullFieldName}>
                    {!field.mandatory ? (
                        <>
                            <FormItem
                                label={
                                    <FormItemLabel>
                                        {getTypeIcon(field.type)}
                                        {field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}
                                    </FormItemLabel>
                                }
                                name={fullFieldName + "_SUBOBJECT_SWITCH"}
                                tooltip={field.description}
                                valuePropName="checked"
                            >
                                <Switch onChange={(e: boolean) => setShowSubobject(e)} />
                            </FormItem>
                            {deleteButton && <Form.Item label=" ">{deleteButton}</Form.Item>}
                        </>
                    ) : (
                        <FormItemLabel>
                            {getTypeIcon(field.type)}
                            {field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}
                            {deleteButton}
                        </FormItemLabel>
                    )}

                    {(field.mandatory || showSubObject) && (
                        <SubObjectFieldWrapper>
                            {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                projectConfig.subObjects[field.subObjectName].fields.map((subField) => {
                                    return <ProcessFieldInput projectConfig={projectConfig} field={subField} parentPath={fullFieldName} />
                                })
                            }
                        </SubObjectFieldWrapper>
                    )}
                </SubObjectFormItemWrapper>
            )
            break
        case "CONNECTION_BELONGSTO":
        case "CONNECTION_CONTAINS":
            if (field.connectionUnderlyingField) {
                // no need to add field name
                formItem = <ProcessFieldInput projectConfig={projectConfig} field={field.connectionUnderlyingField} parentPath={parentPath} />
            }
            break
        case "CUSTOMENDPOINT":
        case "EXTERNALENDPOINT":
        case "USERSELF":
            formItem = <div key={fullFieldName}></div>
            break
        default:
            formItem = (
                <div key={fullFieldName} style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <FormItemLabel>
                        {field.type}
                        {field.name + (field.isArray ? (ind ? ` : ${ind}` : " : 0") : "")}
                        {deleteButton}
                    </FormItemLabel>
                    {"NOT SUPPORTED YET"}
                </div>
            )
            break
    }

    if (ind || !field.isArray) return formItem

    const [additionalItemsInd, setAdditionalItemsInd] = useState([] as number[])

    return (
        <>
            {formItem}

            {additionalItemsInd.map((ai) => (
                <ProcessFieldInput
                    key={fullFieldName + `-X-${ai}`}
                    projectConfig={projectConfig}
                    field={field}
                    parentPath={parentPath}
                    ind={additionalItemsInd.indexOf(ai) + 1}
                    form={form}
                    deleteLastAdditionalItem={
                        additionalItemsInd.indexOf(ai) == -1
                            ? undefined
                            : () => {
                                  const updatedItems = [...additionalItemsInd]
                                  updatedItems.splice(additionalItemsInd.indexOf(ai), 1)
                                  setAdditionalItemsInd(updatedItems)
                              }
                    }
                />
            ))}

            <Form.Item label=" ">
                <Button
                    type="dashed"
                    onClick={() =>
                        setAdditionalItemsInd(
                            additionalItemsInd.length > 0 ? additionalItemsInd.concat([additionalItemsInd[additionalItemsInd.length - 1] + 1]) : [1]
                        )
                    }
                    icon={<PlusOutlined />}
                >
                    Add {field.name}
                </Button>
            </Form.Item>
        </>
    )
}

function processSubObject(values: any, subObjectKey: string) {
    const subObjectValues: { [key: string]: any } = {}
    const subObjectFields = Object.keys(values).filter((key) => key.startsWith(subObjectKey + "."))
    for (const subObjectField of subObjectFields) {
        const subObjectFieldWithoutPrefix = subObjectField.replace(subObjectKey + ".", "")
        if (
            !subObjectFieldWithoutPrefix.includes(".") &&
            subObjectFieldWithoutPrefix.includes("_SUBOBJECT_SWITCH") &&
            values[subObjectKey + "." + subObjectFieldWithoutPrefix] === true
        ) {
            subObjectValues[subObjectFieldWithoutPrefix] = processSubObject(values, subObjectKey + "." + subObjectFieldWithoutPrefix)
        } else if (!subObjectFieldWithoutPrefix.includes(".") && !subObjectFieldWithoutPrefix.includes("_SUBOBJECT_SWITCH")) {
            subObjectValues[subObjectFieldWithoutPrefix] =
                typeof values[subObjectField] === "object" && values[subObjectField] !== null
                    ? values[subObjectField].toISOString()
                    : values[subObjectField]
        }
    }

    return subObjectValues
}

const prepareInput = (values: any) => {
    // console.log(JSON.stringify(values))
    const mutationInput: { [key: string]: any } = {}

    const topValuesKeys = Object.keys(values).filter((key) => !key.includes("_SUBOBJECT_SWITCH") && !key.includes("."))
    for (const topKey of topValuesKeys) {
        // console.log(JSON.stringify({ topKey, value: values[topKey] }))
        mutationInput[topKey] = typeof values[topKey] === "object" && values[topKey] !== null ? values[topKey].toISOString() : values[topKey]
    }

    const arrays = Object.keys(values).filter((key) => key.includes(".0"))
    for (const arr of arrays) {
        const resultingArr = []
        const keyName = arr.split(".0")[0]
        // console.log(keyName)
        const arrItems = Object.keys(values).filter((key) => key.startsWith(keyName))
        // console.log(arrItems)
        for (let ind = 0; true; ind++) {
            const selectedItemFields = arrItems.filter((key) => key.startsWith(keyName + `.${ind}`))
            // console.log(selectedItemFields)
            if (
                ((selectedItemFields.length == 1 || values[selectedItemFields[0]] == false) && selectedItemFields[0].endsWith("_SUBOBJECT_SWITCH")) ||
                selectedItemFields.length == 0
            )
                break

            const subValues: any = {}
            for (const sif of selectedItemFields) {
                const fieldName = sif.split(keyName + `.${ind}.`)[1]
                // console.log(fieldName)
                // console.log(sif)
                // console.log(values[sif])
                if (fieldName) {
                    subValues[fieldName] = values[sif]
                    resultingArr.push(prepareInput(subValues))
                } else if (values[sif]) {
                    resultingArr.push(values[sif])
                }
            }
        }
        if (resultingArr.length > 0) mutationInput[keyName] = resultingArr
    }

    const subObjectKeys = Object.keys(values).filter((key) => !key.includes(".") && key.includes("_SUBOBJECT_SWITCH"))
    for (const subObjectKey of subObjectKeys) {
        if (!values[subObjectKey]) continue
        const subObjectKeyWithoutSuffix = subObjectKey.replace("_SUBOBJECT_SWITCH", "")
        const subObjectValues = processSubObject(values, subObjectKeyWithoutSuffix)
        mutationInput[subObjectKeyWithoutSuffix] = subObjectValues
    }

    // console.log("mutationInput", mutationInput)
    return mutationInput
}

/**
 * Render universal form based on the provided array of fields
 *
 * @param {Object} props
 * @param props.setMutationFormValues is used to set and store remote values for each form field
 * @param props.submitForm is used to trigger submission from inside form
 *
 * @returns ReactNode
 */
const MutationForm = (props: {
    form: FormInstance
    isSubmitting?: boolean
    submitForm?: () => void
    setMutationFormValues?: (input: any) => void
    fields?: EntityField[]
    allowedFields?: string[]
    projectConfig: Config
    withSubmitButton?: boolean
    encapsulated?: boolean
    noImmutable?: boolean
}) => {
    const {
        form,
        isSubmitting,
        submitForm,
        setMutationFormValues,
        fields,
        allowedFields,
        projectConfig,
        withSubmitButton,
        encapsulated,
        noImmutable,
    } = props

    return (
        <Form
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 20 }}
            // labelWrap
            colon={false}
            layout="horizontal"
            style={{ maxWidth: "800px" }}
            scrollToFirstError
            form={form}
            name="signupProfile"
            onFinish={(values: any) => {
                // console.log("Received values of form: ", values)
                //
                // {
                //     acceptedTerms: undefined
                //     email: "em"
                //     firstName: "fn"
                //     lastName: "ln"
                //     phoneNumber: "pn"
                // }
                //

                if (setMutationFormValues) {
                    const mutationInput: { [key: string]: any } = prepareInput(values)
                    setMutationFormValues(mutationInput)
                }
                if (withSubmitButton && submitForm) {
                    submitForm()
                }
            }}
            // onFieldsChange={(changedFields, allFields) => {
            //     console.log("Received changedFields of form: ", changedFields)
            //     // [{
            //     // errors: Array []
            //     // name: Array [ "firstName" ]
            //     // originRCField: true
            //     // touched: true
            //     // validated: true
            //     // validating: false
            //     // value: "fn"
            //     // warnings: Array []
            //     // }...]
            // }}
            onValuesChange={(changedValues, allValues) => {
                // console.log("All values: ", allValues)
                // console.log("Received changedValues of form: ", changedValues)
                //
                // {
                //     acceptedTerms: undefined
                //     email: "e"
                //     firstName: "m"
                //     lastName: "l"
                //     phoneNumber: undefined
                //     text: "t"
                //     time: Object { "$L": "en", "$y": 2023, "$M": 9, … }
                // }
                //

                if (setMutationFormValues) {
                    const mutationInput: { [key: string]: any } = prepareInput(allValues)
                    setMutationFormValues(mutationInput)
                }
            }}
            validateMessages={{
                required: "Please provide ${label}",
            }}
        >
            <FormFieldsWrapper encapsulated={encapsulated}>
                {fields?.map((field) => {
                    // console.log(JSON.stringify(field))
                    if (allowedFields && !allowedFields.includes(field.name)) return null
                    if (noImmutable && !field.mutable) return null
                    return <ProcessFieldInput projectConfig={projectConfig} field={field} form={form} />
                })}
            </FormFieldsWrapper>

            {withSubmitButton && (
                <Form.Item label=" ">
                    <Button type="primary" htmlType="submit" loading={isSubmitting}>
                        Finish sign up
                    </Button>
                </Form.Item>
            )}
        </Form>
    )
}

const SignUpProfile = (props: {
    sendSignUp: () => void
    sendProfile: (input: any) => void
    allowedFields: string[] | undefined
    isCreating: boolean
    title: string
    projectConfig: Config
    userFields: EntityField[]
}) => {
    const [form] = Form.useForm()
    const { sendSignUp, sendProfile, allowedFields, isCreating, title, projectConfig, userFields } = props

    return (
        <SignUpProfileWrapper>
            <Title level={4} style={{ marginTop: 0, marginBottom: Spaces.normal }}>
                <UserOutlined style={{ marginRight: Spaces.small }} />
                {title}
            </Title>
            <MutationForm
                form={form}
                submitForm={sendSignUp}
                setMutationFormValues={sendProfile}
                isSubmitting={isCreating}
                fields={userFields}
                allowedFields={allowedFields}
                projectConfig={projectConfig}
                withSubmitButton
                encapsulated
            />
        </SignUpProfileWrapper>
    )
}

const SignUpProfileWrapper = styled.div`
    padding: 0 ${Spaces.normal};
`

const FormItemLabel = styled.div`
    display: flex;
    direction: row;
    gap: ${Spaces.small};
    max-width: 155px;
    white-space: normal;
    word-break: break-word;
    align-items: center;
`

const FormItem = styled(Form.Item)`
    .ant-form-item-control-input-content {
        display: flex;
        gap: ${Spaces.small};
    }
`

const FormFieldsWrapper = styled.div<{ encapsulated?: boolean }>`
    /* ${ScrollableBlock} */
    max-height: ${(props) => (props.encapsulated ? "285px" : "unset")};
    overflow-y: auto;
    margin-bottom: ${Spaces.medium};
`

const SubObjectFormItemWrapper = styled.div`
    & > div:first-child {
        margin-bottom: ${Spaces.medium};
    }
`

const SubObjectFieldWrapper = styled.div`
    border: 1px solid ${Colors.grayNormal};
    border-radius: ${StyleHelpers.radiusMedium};
    padding: ${Spaces.medium};
    margin-bottom: ${Spaces.medium};
    padding-bottom: 0;
`

export { MutationForm, SignUpProfile }
