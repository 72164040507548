import React from "react"
import styled from "styled-components"
import { useContext, useState } from "react"
import { useActor } from "@xstate/react"
import { Link, useLocation } from "react-router-dom"
import { Divider } from "antd"
import { UserOutlined } from "@ant-design/icons"
import Intercom, { boot, show, hide } from "@intercom/messenger-js-sdk"

import { GlobalStateContext } from ".."
import { Colors, ItemWithFadeInAnimation, Spaces, StyleHelpers } from "./global"
import { ReactComponent as LogoImg } from "../assets/logo.svg"
import { ReactComponent as HoverLogoImg } from "../assets/hover_logo.svg"

const LogoNav = () => {
    const [isHovered, setIsHovered] = useState(false)
    const [isOpen, setOpen] = useState(false)

    const globalServices = useContext(GlobalStateContext)
    const [state, send] = useActor(globalServices.authService)

    const location = useLocation()
    const urlParts = location.pathname.split("/")
    // TODO: find better way to get projectId (P.S. Use useParams() is not working)
    const projectId = urlParts.length > 2 ? urlParts[1] : undefined

    const session = localStorage.getItem("session")
    const sessionObj = session && JSON.parse(session)

    if (sessionObj?.["username"]) {
        Intercom({
            app_id: "konxt1by",
            user_id: sessionObj.userData?._id,
            name: sessionObj["username"], // TODO: first need to implement form after sign-up to collect profile details and on the BE endpoint to save it in DB
            email: sessionObj.userData?.email ?? sessionObj["username"],
            created_at: sessionObj.userData?.createdAt,
            user_hash: sessionObj.userData?.intercomHash,
            hide_default_launcher: true,
        })
    }

    const Logo = (
        <LogoWrapper
            onMouseOver={() => {
                setIsHovered(true)
            }}
            onMouseOut={() => {
                setIsHovered(false)
            }}
            onClick={() => setOpen(!isOpen)}
        >
            {isHovered ? <HoverLogoImg /> : <LogoImg />}
        </LogoWrapper>
    )

    // const menuIcon = (
    //     <MenuWrapper onClick={() => setOpen(true)}>
    //         <MenuOutlined />
    //     </MenuWrapper>
    // )

    // const closeIcon = (
    //     <CloseIconWrapper onClick={() => setOpen(false)}>
    //         <CloseIcon />
    //     </CloseIconWrapper>
    // )

    if (!isOpen)
        return (
            <>
                {/* {menuIcon} */}
                {Logo}
            </>
        )

    return (
        <NavShadow onClick={() => setOpen(!isOpen)}>
            <Nav reversed onClick={(e) => e.stopPropagation()}>
                {/* {closeIcon} */}
                <NavItems>
                    <NavItem onClick={() => setOpen(!isOpen)} selected={urlParts[1] === "projects"} to={`/projects`}>
                        Projects
                    </NavItem>

                    {projectId && (
                        <>
                            <Divider />
                            <NavItem onClick={() => setOpen(!isOpen)} selected={urlParts[2] === "designer"} to={`/${projectId}/designer`}>
                                Designer
                            </NavItem>
                            <NavItem onClick={() => setOpen(!isOpen)} selected={urlParts[2] === "simulator"} to={`/${projectId}/simulator`}>
                                Live API
                            </NavItem>
                            <NavItem
                                onClick={() => setOpen(!isOpen)}
                                selected={urlParts[2] === "explorer"}
                                to={`/projects/${projectId}/explorer`}
                                disabled
                            >
                                Data explorer
                            </NavItem>
                            <NavItem
                                onClick={() => setOpen(!isOpen)}
                                selected={urlParts[2] === "metrics"}
                                to={`/projects/${projectId}/metrics`}
                                disabled
                            >
                                Metrics
                            </NavItem>
                        </>
                    )}

                    <Divider />

                    <UserName>
                        <UserOutlined style={{ marginRight: Spaces.small }} />
                        <div className="text">{sessionObj["username"]}</div>
                    </UserName>

                    <NavItem onClick={() => setOpen(!isOpen)} to={`/account`} disabled>
                        Account
                    </NavItem>

                    <NavItemBasic onClick={() => show()}>Support</NavItemBasic>

                    <NavItem onClick={() => send("LOG_OUT")} to={`/`}>
                        Log out
                    </NavItem>
                </NavItems>
                {Logo}
            </Nav>
        </NavShadow>
    )
}

const LogoWrapper = styled.div`
    background-color: ${Colors.transparentWhiteBackground};
    box-shadow: ${StyleHelpers.whiteGlowBoxShadow};
    border-radius: 10px;
    position: fixed;
    bottom: 15px;
    left: 15px;
    height: 50px;
    cursor: pointer;
    z-index: 2;
`

// const CloseIcon = styled(CloseOutlined)`
//     transition: transform 0.1s ease-in-out;
//     transform: rotate(0deg);
//     &:hover {
//         transform: rotate(90deg);
//     }
// `

// const MenuWrapper = styled.div`
//     background-color: ${Colors.transparentWhiteBackground};
//     box-shadow: ${StyleHelpers.whiteGlowBoxShadow};
//     border-radius: 10px;
//     position: fixed;
//     top: 15px;
//     left: 15px;
//     cursor: pointer;
// `

// const CloseIconWrapper = styled.div`
//     background-color: ${Colors.transparentWhiteBackground};
//     box-shadow: ${StyleHelpers.whiteGlowBoxShadow};
//     border-radius: 10px;
//     position: fixed;
//     top: 15px;
//     left: 15px;
//     height: 50px;
//     cursor: pointer;
// `

const NavShadow = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${Colors.transparentDarkFrostyBackground};
    backdrop-filter: ${StyleHelpers.blur};
    z-index: 1;

    opacity: 0; /* start with opacity set to 0 */
    animation-name: fade-in; /* specify the animation */
    animation-duration: 0.5s; /* specify the duration */
    animation-fill-mode: forwards; /* keep the last frame of the animation */
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

const Nav = styled(ItemWithFadeInAnimation)`
    position: fixed;
    bottom: 95px;
    left: 30px;
    width: 300px;
    max-height: calc(100vh - 95px - 30px);
    overflow: scroll;
    padding: 60px 0;
    border-radius: 20px;
    background-color: ${Colors.transparentWhiteBackground};
    box-shadow: ${StyleHelpers.boldBoxShadow};
`

const NavItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spaces.normal};
`

const NavItem = styled(Link)<{ disabled?: boolean; selected?: boolean }>`
    padding: 0 ${Spaces.xLarge};
    width: 300px;
    line-height: 40px;
    font-size: 25px;
    color: ${(props) => (props.disabled ? Colors.grayNormal : props.selected ? "white" : "black")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    text-decoration: none;
    background-color: ${(props) => (props.selected ? Colors.primary : "unset")};

    &:hover {
        background-color: ${(props) => (props.disabled ? "unset" : props.selected ? Colors.primary : Colors.grayLight)};
    }
`

const NavItemBasic = styled.div`
    padding: 0 ${Spaces.xLarge};
    width: 300px;
    line-height: 40px;
    font-size: 25px;
    color: black;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: ${Colors.grayLight};
    }
`

const UserName = styled.div<{ onClick?: any }>`
    display: block;
    padding: 0 ${Spaces.xLarge};
    font-size: 20px;
    line-height: 20px;
    .text {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 224px;
        display: inline-block;
    }
`

export default LogoNav
