import styled from "styled-components"
import { Empty, Button, Divider, Checkbox, Tooltip, Input } from "antd"
import { PlusCircleFilled, QuestionCircleOutlined } from "@ant-design/icons"

import { Centered, Colors, Spaces, StyleHelpers } from "../../global"
import type { AccessItem, User } from "../../../coreTypes/config"
import AccessPermissionForm, { AccessPermissionFormProps } from "./AccessPermissionForm"
import AccessPermissionItem from "./AccessPermissionItem"

export default function AccessPermissionsContainer(props: {
    entityName: string
    users: User[]
    accessItems: AccessItem[]
    isPermissionForm: boolean
    isFieldForm: boolean
    sendEditAccessPermissionItem: (accessItem?: AccessItem, accessItemIndex?: number) => void
    isInvitable: boolean
    setInvitable: (groupName: string) => void
    setEmailInvitationUrl: (url: string) => void
    setUserInvitationUrl: (url: string) => void
    accessPermisionFormProps: AccessPermissionFormProps
}) {
    const {
        entityName,
        users,
        accessItems,
        isPermissionForm,
        isFieldForm,
        sendEditAccessPermissionItem,
        isInvitable,
        setInvitable,
        setEmailInvitationUrl,
        setUserInvitationUrl,
        accessPermisionFormProps,
    } = props

    return (
        <>
            <AccessPermissionsWrapper>
                <h2>Access permissions</h2>
                {isPermissionForm ? (
                    <AccessPermissionForm {...accessPermisionFormProps} />
                ) : (
                    <>
                        {accessItems.length === 0 ? (
                            <Centered>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No access permissions for this entity created yet" />
                            </Centered>
                        ) : (
                            accessItems.map((accessItem: AccessItem, index: number) => {
                                return (
                                    <AccessPermissionItem
                                        entityName={entityName}
                                        isFieldForm={isFieldForm}
                                        sendEditAccessPermissionItem={() => {
                                            sendEditAccessPermissionItem(accessItem, index)
                                        }}
                                        accessItem={accessItem}
                                        key={accessItem.type + index}
                                        index={index}
                                    />
                                )
                            })
                        )}
                        <Centered>
                            <Button
                                disabled={isFieldForm}
                                icon={<PlusCircleFilled />}
                                type="primary"
                                onClick={() => {
                                    sendEditAccessPermissionItem()
                                }}
                            >
                                Add permission
                            </Button>
                        </Centered>
                    </>
                )}
            </AccessPermissionsWrapper>

            {isInvitable && !isPermissionForm && (
                <>
                    <Divider />
                    <h2>
                        {"Invitation permissions "}
                        <Tooltip
                            overlayInnerStyle={{ width: "400px" }}
                            title={`"${accessPermisionFormProps.userForm?.user.groupName}" can be invited to sign up by any users from the following selected groups`}
                        >
                            <QuestionCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
                        </Tooltip>
                    </h2>
                    {users.map((u) => (
                        <CheckboxesWrapper>
                            <Checkbox
                                checked={accessPermisionFormProps.userForm?.user.invitableBy?.includes(u.groupName)}
                                onClick={() => {
                                    setInvitable(u.groupName)
                                }}
                            >
                                {u.groupName}
                            </Checkbox>
                        </CheckboxesWrapper>
                    ))}
                    <UrlsWrapper>
                        <div>
                            <Input
                                placeholder="Email invitation destination URL"
                                title="https://example.com/profile-sign-up/?email=#email#&code=#invitationCode#"
                                style={{ width: "300px" }}
                                value={accessPermisionFormProps.userForm?.user.acceptEmailInvitationDestinationUrl}
                                onChange={(e) => {
                                    setEmailInvitationUrl(e.target.value)
                                }}
                            />
                            <Tooltip
                                overlayInnerStyle={{ width: "400px" }}
                                title={
                                    `"Email invitation" flow means there is NO user profile yet and following this URL from email message person has to submit profile details through GQL create mutation. ` +
                                    `Should contain mandatory #email# and #invitationCode# placeholders to hadnle further API call within your app. ` +
                                    `Reference URL: "https://example.com/profile-sign-up/?email=#email#&code=#invitationCode#"`
                                }
                            >
                                <QuestionCircleOutlined style={{ marginLeft: Spaces.normal }} />
                            </Tooltip>
                        </div>
                        <div>
                            <Input
                                placeholder="User invitation destination URL"
                                title="https://example.com/set-password/?email=#email#&code=#invitationCode#"
                                style={{ width: "300px" }}
                                value={accessPermisionFormProps.userForm?.user.acceptUserInvitationDestinationUrl}
                                onChange={(e) => {
                                    setUserInvitationUrl(e.target.value)
                                }}
                            />
                            <Tooltip
                                overlayInnerStyle={{ width: "400px" }}
                                title={
                                    `"User invitation" flow means there is existing user profile and following this URL from email message user has to set new password only.` +
                                    `Should contain mandatory #email# and #invitationCode# placeholders to hadnle further API call within your app. ` +
                                    `Reference URL: "https://example.com/set-password/?email=#email#&code=#invitationCode#"`
                                }
                            >
                                <QuestionCircleOutlined style={{ marginLeft: Spaces.normal }} />
                            </Tooltip>
                        </div>
                    </UrlsWrapper>
                </>
            )}
        </>
    )
}

const AccessPermissionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${Spaces.large};

    min-height: 50vh;
    padding-right: ${Spaces.medium};
`

const CheckboxesWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const UrlsWrapper = styled.div`
    padding: ${Spaces.large};
    padding-left: 0;
    display: flex;
    flex-direction: column;
    gap: ${Spaces.normal};
`
