import { useEffect, useState } from "react"
import styled from "styled-components"
import { Popconfirm, Input, Button, Tooltip, Divider, Select, Dropdown, TreeSelect, Typography } from "antd"
import {
    PlusCircleFilled,
    FilterOutlined,
    DeleteOutlined,
    ReloadOutlined,
    RollbackOutlined,
    UserOutlined,
    CheckCircleFilled,
    CheckOutlined,
    EllipsisOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons"
import { DefaultOptionType } from "antd/es/select"

import { Colors, Spaces, StyleHelpers } from "../../global"
import { Switcher } from "../../Switcher"
import { OptionItemWrapper } from "../FieldForm"
import AllowedFieldsTree from "./AllowedFieldsTree"
import type { User, FieldWithSelfAsSubfield, EntityField, Config, ConfigEntity } from "../../../coreTypes/config"
import type { AccessFormType, EntityFormType, UserFormType } from "../../../machines/DesignerModel"
import { cleanInputNameStartingUpper, GetEntityAllFields } from "../../../helpers/functions"
import { ButtonGroupWrapper, SecondaryButton } from "../DataEntitiesTab/EntitiesFormWithFields"

const { Option, OptGroup } = Select
const { TextArea } = Input
const { Title } = Typography

function flattenFieldRoute(fieldWithSub: FieldWithSelfAsSubfield) {
    const { fieldName, subfield } = fieldWithSub
    const result = [fieldName]
    if (subfield) result.push(...flattenFieldRoute(subfield))

    return result
}

function unwrapOption(
    field: EntityField,
    projectConfig: Config,
    currentLevel: number,
    limitLevels = 3,
    treeBranchParentUniqueEntities: string[] = [],
    validType?: string,
    parentPath?: string
) {
    const children: DefaultOptionType[] = []

    if (validType && ["INT", "FLOAT", "DOUBLE"].includes(field.type) && !["INT", "FLOAT", "DOUBLE"].includes(validType)) return
    if (validType && ["DATE", "TIME"].includes(field.type) && !["DATE", "TIME"].includes(validType)) return

    let newValue: string

    switch (field.type) {
        case "INT":
        case "FLOAT":
        case "DOUBLE":
        case "DATE":
        case "TIME":
        case "STRING":
        case "BOOLEAN":
        case "ENUM":
        case "USER_ID":
        case "OBJECT_ID":
        case "EMAIL":
        case "PHONENUMBER":
            if (
                validType &&
                field.type !== validType &&
                (!(["INT", "FLOAT", "DOUBLE"].includes(field.type) && ["INT", "FLOAT", "DOUBLE"].includes(validType)) ||
                    !(["DATE", "TIME"].includes(field.type) && ["DATE", "TIME"].includes(validType)))
            )
                return

            newValue = parentPath ? parentPath + "." + field.name + "*" + field.type : field.name + "*" + field.type

            return {
                value: newValue,
                key: newValue,
                label: (
                    <div style={{ display: "flex", flexDirection: "row", gap: Spaces.small }}>
                        <div style={{ color: "black" }}>{field.name}</div>
                        <i style={{ color: Colors.grayNormal }}>{field.type}</i>
                    </div>
                ),
            }
        case "SUBOBJECT":
            if (currentLevel >= limitLevels) break
            // Find subobject in projectConfig and process its fields as children
            const subobjectFields = projectConfig.subObjects?.[field.subObjectName!].fields
            if (!subobjectFields) break
            for (const f of subobjectFields) {
                if (f.connectionUnderlyingField) {
                    newValue = parentPath
                        ? parentPath + "." + field.name + "." + f.connectionUnderlyingField.name + "*" + f.connectionUnderlyingField.type
                        : field.name + "." + f.connectionUnderlyingField.name + "*" + f.connectionUnderlyingField.type

                    if (children.findIndex((c) => c.value == newValue) == -1)
                        children.push({
                            value: newValue,
                            key: newValue,
                            label: (
                                <div style={{ display: "flex", flexDirection: "row", gap: Spaces.small }}>
                                    <div style={{ color: "black" }}>{f.connectionUnderlyingField.name}</div>
                                    <i style={{ color: Colors.grayNormal }}>{f.connectionUnderlyingField.type}</i>
                                </div>
                            ),
                        })
                }

                const option = unwrapOption(
                    f,
                    projectConfig,
                    currentLevel + 1,
                    limitLevels,
                    treeBranchParentUniqueEntities,
                    undefined,
                    parentPath ? parentPath + "." + field.name : field.name
                )
                if (option && children.findIndex((c) => c.value == option.value) == -1) children.push(option)
            }

            newValue = parentPath ? parentPath + "." + field.name + "*" + field.type : field.name + "*" + field.type

            return {
                value: newValue,
                key: newValue,
                label: <div style={{ color: "black" }}>{field.name}</div>,
                selectable: false,
                disabled: true,
                children: children,
            }
        case "CONNECTION_BELONGSTO":
        case "CONNECTION_CONTAINS":
            if (currentLevel >= limitLevels) break
            // Find connected entity in projectConfig and process its fields as children
            if (treeBranchParentUniqueEntities.includes(field.connectedEntityName!)) break

            const connectedEntityFields = GetEntityAllFields(projectConfig, field.connectedEntityName!)
            if (!connectedEntityFields) break

            for (const f of connectedEntityFields) {
                if (f.connectionUnderlyingField) {
                    newValue = parentPath
                        ? parentPath + "." + field.name + "." + f.connectionUnderlyingField.name + "*" + f.connectionUnderlyingField.type
                        : field.name + "." + f.connectionUnderlyingField.name + "*" + f.connectionUnderlyingField.type

                    if (children.findIndex((c) => c.value == newValue) == -1)
                        children.push({
                            value: newValue,
                            key: newValue,
                            label: (
                                <div style={{ display: "flex", flexDirection: "row", gap: Spaces.small }}>
                                    <div style={{ color: "black" }}>{f.connectionUnderlyingField.name}</div>
                                    <i style={{ color: Colors.grayNormal }}>{f.connectionUnderlyingField.type}</i>
                                </div>
                            ),
                        })
                }

                const option = unwrapOption(
                    f,
                    projectConfig,
                    currentLevel + 1,
                    limitLevels,
                    treeBranchParentUniqueEntities.concat([field.connectedEntityName!]),
                    undefined,
                    parentPath ? parentPath + "." + field.name : field.name
                )
                if (option && children.findIndex((c) => c.value == option.value) == -1) children.push(option)
            }

            newValue = parentPath ? parentPath + "." + field.name + "*" + field.type : field.name + "*" + field.type

            return {
                value: newValue,
                key: newValue,
                label: <div style={{ color: "black" }}>{field.name}</div>,
                selectable: false,
                disabled: true,
                children: children,
            }
        case "USERSELF": // Not needed because it repeats the same object
            return
        case "EXTERNALENDPOINT":
        case "CUSTOMENDPOINT":
        default:
            return
    }
}

function getUserFields(userGroups: User[], accessForm: AccessFormType, projectConfig: Config, validType: string) {
    const userTreeData: DefaultOptionType[] = []
    const selectedUserGroup = userGroups.find((userGroup) => userGroup.groupName == accessForm.accessItem.userGroupName)
    if (selectedUserGroup) {
        const fields = Object.keys(selectedUserGroup.userData.defaultFields).map(
            (name) =>
                ({
                    name,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    type: selectedUserGroup.userData.defaultFields[name],
                }) as EntityField
        )
        for (const uf of fields.concat(selectedUserGroup.userData.fields) || []) {
            if (uf.connectionUnderlyingField) {
                if (validType && uf.connectionUnderlyingField.type !== validType) continue
                const newValue = uf.connectionUnderlyingField.name + "*" + uf.connectionUnderlyingField.type
                if (userTreeData.findIndex((f) => f.value == newValue) == -1) {
                    userTreeData.push({
                        value: newValue,
                        key: newValue,
                        label: (
                            <div style={{ display: "flex", flexDirection: "row", gap: Spaces.small }}>
                                <div style={{ color: "black" }}>{uf.connectionUnderlyingField.name}</div>
                                <i style={{ color: Colors.grayNormal }}>{uf.connectionUnderlyingField.type}</i>
                            </div>
                        ),
                    })
                }
            }

            // TODO: allow more than one depth of nested fields in the future <- requires BE modification first
            const option = unwrapOption(uf, projectConfig, 0, 0, [], validType)
            if (option && userTreeData.findIndex((f) => f.value == option.value) == -1) userTreeData.push(option)
        }
    }

    return userTreeData
}

function isValidAccessItem(accessItem: AccessFormType["accessItem"]): boolean {
    if (accessItem.rule?.type === "AND" && (!accessItem.rule?.name || !accessItem.rule?.andConditions || accessItem.rule?.andConditions.length === 0))
        return false

    if (accessItem.rule?.andConditions) {
        for (const ac of accessItem.rule?.andConditions) {
            if (!ac.objectFieldRoute.fieldName || !ac.userAccessValue) return false
        }
    }

    if (accessItem.fields !== "ALL" && accessItem.fields.length === 0) return false

    return true
}

export interface AccessPermissionFormProps {
    userGroups: User[]
    dataEntities: ConfigEntity[]
    accessForm: AccessFormType
    addCondition: () => void
    setUserGroupName: (groupName: string) => void
    projectConfig: Config
    userForm?: UserFormType
    entityForm?: EntityFormType
    switchRestriction: () => void
    selectAndConditionObjectField: (val: string, index: number) => void
    switchAccessFields: () => void
    changeFieldAccess: (fieldPath: string, accessType: string) => void
    changeAccessType: () => void
    sendDeleteAccessPermission: () => void
    sendResetAccessermission: () => void
    sendCancelAccessPermission: () => void
    sendSaveAccessPermission: () => void
    changeRuleName: (name: string) => void
    changeDescription: (description: string) => void
    setUserAccessValue: (val: string, index: number) => void
    deleteCondition: (index: number) => void
}

export default function AccessPermissionForm(props: AccessPermissionFormProps) {
    const {
        userGroups,
        dataEntities,
        accessForm,
        addCondition,
        setUserGroupName,
        projectConfig,
        userForm,
        entityForm,
        switchRestriction,
        selectAndConditionObjectField,
        switchAccessFields,
        changeFieldAccess,
        sendDeleteAccessPermission,
        sendResetAccessermission,
        sendCancelAccessPermission,
        sendSaveAccessPermission,
        changeRuleName,
        changeDescription,
        setUserAccessValue,
        deleteCondition,
    } = props
    const [isHovered, setIsHovered] = useState(false)
    const [ruleName, setRuleName] = useState(accessForm.accessItem.rule?.name)

    useEffect(() => {
        setRuleName(accessForm.accessItem.rule?.name)
    }, [accessForm.accessItem.rule?.name])

    const menuItems: any = []
    if (accessForm.originalAccessItem)
        menuItems.push({
            key: "delete",
            label: (
                <Popconfirm title="Are you sure you want to delete?" onConfirm={sendDeleteAccessPermission} okText="Yes" cancelText="No">
                    delete
                </Popconfirm>
            ),
            icon: <DeleteOutlined />,
        })

    if (accessForm.originalAccessItem && JSON.stringify(accessForm.originalAccessItem) !== JSON.stringify(accessForm.accessItem))
        menuItems.push({
            key: "reset",
            label: (
                <Popconfirm
                    title="Are you sure you want to reset without saving changes?"
                    onConfirm={sendResetAccessermission}
                    okText="Yes"
                    cancelText="No"
                >
                    reset
                </Popconfirm>
            ),
            icon: <ReloadOutlined />,
        })

    const menuItemAction = (menuItem: any) => {
        switch (menuItem.key) {
            case "delete":
                sendDeleteAccessPermission()
                break
            case "reset":
                sendResetAccessermission()
                break
        }
    }

    const localProjectConfig = {
        ...projectConfig,
    }
    const localDataEntities = JSON.parse(JSON.stringify(dataEntities)) as ConfigEntity[]
    const localUserGroups = JSON.parse(JSON.stringify(userGroups)) as User[]

    let topLevelFields: EntityField[] = []
    const objectTreeData: DefaultOptionType[] = []

    if (userForm) {
        const ugIndex = localUserGroups.findIndex((ug) => ug.groupName == userForm.originalUser?.groupName)
        if (ugIndex !== -1) {
            localUserGroups.splice(ugIndex, 1, userForm.user)
        } else if (userForm.originalUser) {
            localUserGroups.push(userForm.user)
        }
        localProjectConfig["users"] = JSON.parse(JSON.stringify(localUserGroups))

        const userDefaultFields = Object.keys(userForm.user.userData.defaultFields).map(
            (name) =>
                ({
                    name,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    type: userForm.user.userData.defaultFields[name],
                }) as EntityField
        )
        topLevelFields = userDefaultFields.concat(userForm.user.userData.fields)
        for (const uf of topLevelFields) {
            if (uf.connectionUnderlyingField) {
                const newValue = uf.connectionUnderlyingField.name + "*" + uf.connectionUnderlyingField.type
                if (objectTreeData.findIndex((f) => f.value == newValue) == -1) {
                    objectTreeData.push({
                        value: newValue,
                        key: newValue,
                        label: (
                            <div style={{ display: "flex", flexDirection: "row", gap: Spaces.small }}>
                                <div style={{ color: "black" }}>{uf.connectionUnderlyingField.name}</div>
                                <i style={{ color: Colors.grayNormal }}>{uf.connectionUnderlyingField.type}</i>
                            </div>
                        ),
                    })
                }
            }
            const option = unwrapOption(uf, localProjectConfig, 0, 4)
            if (option && objectTreeData.findIndex((f) => f.value == option.value) == -1) objectTreeData.push(option)
        }
    } else if (entityForm) {
        const eIndex = localDataEntities.findIndex((dataEntity) => dataEntity.entityName == entityForm.originalEntity?.entityName)
        if (eIndex !== -1) {
            localDataEntities.splice(eIndex, 1, entityForm.entity)
        } else if (entityForm.originalEntity) {
            localDataEntities.push(entityForm.entity)
        }
        localProjectConfig["entities"] = JSON.parse(JSON.stringify(localDataEntities))

        const entityDefaultFields = Object.keys(entityForm.entity.defaultFields).map(
            (name) =>
                ({
                    name,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    type: entityForm.entity.defaultFields[name],
                }) as EntityField
        )
        topLevelFields = entityDefaultFields.concat(entityForm.entity.fields)
        for (const ef of topLevelFields) {
            if (ef.connectionUnderlyingField) {
                const newValue = ef.connectionUnderlyingField.name + "*" + ef.connectionUnderlyingField.type
                if (objectTreeData.findIndex((f) => f.value == newValue) == -1) {
                    objectTreeData.push({
                        value: newValue,
                        key: newValue,
                        label: (
                            <div style={{ display: "flex", flexDirection: "row", gap: Spaces.small }}>
                                <div style={{ color: "black" }}>{ef.connectionUnderlyingField.name}</div>
                                <i style={{ color: Colors.grayNormal }}>{ef.connectionUnderlyingField.type}</i>
                            </div>
                        ),
                    })
                }
            }
            const option = unwrapOption(ef, localProjectConfig, 0, 4)
            if (option && objectTreeData.findIndex((f) => f.value == option.value) == -1) objectTreeData.push(option)
        }
    }

    const isAllFields = accessForm.accessItem.fields === "ALL"
    const allowedFieldsSwitcher = (
        <Switcher
            selected={!isAllFields}
            onClick={
                isAllFields
                    ? switchAccessFields
                    : () => {
                          // do nothing here
                      }
            }
        >
            {
                !isAllFields && <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                // : (
                //     <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                // )
            }
            {!isAllFields ? "limited fields allowed" : "Limit allowed fields"}
        </Switcher>
    )

    const isSavable = !accessForm.originalAccessItem || JSON.stringify(accessForm.originalAccessItem) !== JSON.stringify(accessForm.accessItem)

    return (
        <AccessPermissionFormWrapper isHovered={isHovered}>
            <AccessPermissionFormHeader>
                {/* TODO: replace with "User gorup" and "Public" endpoints
                <Selector onClick={changeAccessType}>
                    <SelectorOption selected={accessForm.accessItem.type === "USERGROUP"}>
                        <UserOutlined /> User group
                    </SelectorOption>
                    <SelectorOption selected={accessForm.accessItem.type === "APIKEY"}>
                        <KeyOutlined /> API-key
                    </SelectorOption>
                </Selector> */}

                <div></div>

                <ButtonGroupWrapper>
                    {menuItems.length > 1 && (
                        <MoreWrapper>
                            <Dropdown
                                menu={{
                                    items: menuItems,
                                }}
                                placement="bottomRight"
                            >
                                <EllipsisOutlined style={{ fontSize: "20px", color: Colors.grayDark }} />
                            </Dropdown>
                        </MoreWrapper>
                    )}
                    {menuItems.length == 1 && !isSavable && (
                        <MoreWrapper style={{ margin: `0 ${Spaces.normal}` }}>
                            <Popconfirm
                                title="Are you sure you want to perform this action?"
                                onConfirm={() => {
                                    menuItemAction(menuItems[0])
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="text" shape="circle" icon={menuItems[0].icon} />
                            </Popconfirm>
                        </MoreWrapper>
                    )}

                    {isSavable && (
                        <MoreWrapper style={{ margin: `0 ${Spaces.normal}` }}>
                            <Popconfirm
                                title="Are you sure you want to cancel without saving changes?"
                                onConfirm={sendCancelAccessPermission}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="text" shape="circle" icon={<RollbackOutlined />} />
                            </Popconfirm>
                        </MoreWrapper>
                    )}

                    <SecondaryButton
                        isSolid={isSavable}
                        disabled={!isValidAccessItem(accessForm.accessItem)}
                        type="primary"
                        shape="round"
                        icon={isSavable ? <CheckOutlined /> : <RollbackOutlined />}
                        onClick={sendSaveAccessPermission}
                    >
                        {isSavable ? "Save" : "Back"}
                    </SecondaryButton>
                </ButtonGroupWrapper>
            </AccessPermissionFormHeader>

            <AccessPermissionFormSubHeader>
                <MainSection>
                    {accessForm.accessItem.type === "USERGROUP" && (
                        <Select
                            placeholder="Select user group name..."
                            title="Select user group name..."
                            style={{ width: 225 }}
                            allowClear
                            value={accessForm.accessItem.userGroupName}
                            onSelect={(value) => {
                                setUserGroupName(value)
                            }}
                        >
                            <OptGroup label="User group" key="user group">
                                {localUserGroups.map((e: User) => (
                                    <Option value={e.groupName} key={e.groupName}>
                                        <OptionItemWrapper>
                                            <UserOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />
                                            {e.groupName}
                                        </OptionItemWrapper>
                                    </Option>
                                ))}
                            </OptGroup>
                        </Select>
                    )}
                    {/* TODO: add API key dropdown */}

                    <ExplanatoryText>{` is allowed to access object of type "${userForm?.user.groupName ?? entityForm?.entity.entityName ?? ""}" `}</ExplanatoryText>
                </MainSection>
            </AccessPermissionFormSubHeader>

            <AccessPermissionFormBody>
                <Tooltip
                    mouseEnterDelay={0.4}
                    title={
                        accessForm.accessItem.rule?.type !== "ANY"
                            ? "(enabled) Limited the access to the entity by specifying conditions."
                            : "Enable to limit the access to the entity by specifying conditions."
                    }
                >
                    <Switcher selected={accessForm.accessItem.rule?.type !== "ANY"} onClick={switchRestriction}>
                        {
                            accessForm.accessItem.rule?.type !== "ANY" && (
                                <CheckCircleFilled style={{ fontSize: StyleHelpers.iconSize, color: Colors.background }} />
                            )
                            // : (
                            //     <CloseCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} />
                            // )
                        }
                        {accessForm.accessItem.rule?.type !== "ANY" ? "conditionally restricted" : "Restrict access conditionally"}
                    </Switcher>
                </Tooltip>

                {accessForm.accessItem.rule?.type !== "ANY" && (
                    <RestrictAccessForm>
                        <Input
                            placeholder="Condition name"
                            title="Condition name"
                            size="large"
                            style={{ width: "300px" }}
                            value={ruleName}
                            autoFocus={!accessForm.accessItem.rule?.name}
                            onBlur={(e) => {
                                changeRuleName(e.target.value)
                                setRuleName(cleanInputNameStartingUpper(e.target.value))
                            }}
                            onChange={(e) => {
                                setRuleName(e.target.value)
                            }}
                        />
                        <TextArea
                            placeholder="(optional) Corresponding description of the restriction condition..."
                            title="Corresponding description of the restriction condition..."
                            style={{ width: "500px" }}
                            value={accessForm.accessItem.rule?.description}
                            onChange={(e) => {
                                changeDescription(e.target.value)
                            }}
                            autoSize={{ minRows: 2, maxRows: 5 }}
                        />
                        <ConditionsTitle>
                            <FilterOutlined />
                            Conditions
                            <Tooltip
                                overlayInnerStyle={{ width: "400px" }}
                                title={`"Object field" (object is representing the single instance of the entity) has to be equal to the specific field of the user who's trying to access an object.`}
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </ConditionsTitle>

                        {accessForm.accessItem.rule?.andConditions?.map((ac, index) => {
                            let val: string | undefined = flattenFieldRoute(ac.objectFieldRoute).join(".")
                            if (val) {
                                val += "*" + accessForm.conditionsTypes[index]
                            } else {
                                val = undefined
                            }

                            return (
                                <ConditionWrapper key={index}>
                                    {index !== 0 && <>and</>}
                                    <TreeSelect
                                        showSearch
                                        treeLine={true}
                                        style={{ width: index !== 0 ? "220px" : "258px" }}
                                        value={val}
                                        dropdownStyle={{ maxHeight: 600, overflow: "auto" }}
                                        placeholder="Select Object field..."
                                        title="Select Object field..."
                                        allowClear
                                        treeDefaultExpandAll={val !== undefined}
                                        onChange={(val) => {
                                            selectAndConditionObjectField(val, index)
                                        }}
                                        treeData={objectTreeData}
                                    />

                                    <span>=</span>

                                    <TreeSelect
                                        disabled={!val || (accessForm.accessItem.type === "USERGROUP" && !accessForm.accessItem.userGroupName)}
                                        showSearch
                                        treeLine={true}
                                        style={{ width: "210px" }}
                                        value={accessForm.accessItem.userGroupName && ac.userAccessValue !== "" ? ac.userAccessValue : undefined}
                                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                                        placeholder="Select User field..."
                                        title="Select User field..."
                                        allowClear
                                        // treeDefaultExpandAll
                                        onChange={(val) => {
                                            setUserAccessValue(val, index)
                                        }}
                                        treeData={getUserFields(localUserGroups, accessForm, localProjectConfig, accessForm.conditionsTypes[index])}
                                    />

                                    {index !== 0 && (
                                        <DeleteOutlined
                                            style={{ color: Colors.primary }}
                                            onClick={() => {
                                                deleteCondition(index)
                                            }}
                                        />
                                    )}
                                </ConditionWrapper>
                            )
                        })}

                        <Button
                            icon={<PlusCircleFilled />}
                            type="primary"
                            style={{ marginLeft: Spaces.xLarge }}
                            onClick={() => {
                                addCondition()
                            }}
                        >
                            Add condition
                        </Button>
                    </RestrictAccessForm>
                )}

                <Divider>Allowed fields</Divider>

                {!isAllFields ? (
                    <Popconfirm
                        placement="top"
                        title="Allow full access?"
                        description="Previously selected fields will be removed. And all fields will be allowed."
                        onConfirm={switchAccessFields}
                        // onCancel={() => {}}
                        okText="Allow all"
                        cancelText="Keep selected"
                    >
                        {allowedFieldsSwitcher}
                    </Popconfirm>
                ) : (
                    <Tooltip
                        placement="bottom"
                        overlayInnerStyle={{ width: "600px" }}
                        mouseEnterDelay={0.4}
                        title="If you want to limit the access to the entity for the above User group/API-key by specifying the allowed fields, select corresponding fields below. By default all fields allowed."
                    >
                        {allowedFieldsSwitcher}
                    </Tooltip>
                )}
                {!isAllFields && (
                    <AllowedFieldsTree
                        topLevelFields={topLevelFields}
                        projectConfig={localProjectConfig}
                        accessItem={accessForm.accessItem}
                        changeFieldAccess={changeFieldAccess}
                    />
                )}
            </AccessPermissionFormBody>
        </AccessPermissionFormWrapper>
    )
}

const AccessPermissionFormWrapper = styled.div<{ isHovered: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${Spaces.medium};

    padding: ${Spaces.normal} ${Spaces.normal} 120px;
    /* background-color: ${(props) => (props.isHovered ? Colors.grayLight : "unset")}; */
    border-radius: ${StyleHelpers.radiusSmall};
`

const AccessPermissionFormHeader = styled.div`
    display: flex;
    direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding-left: ${Spaces.large};
`

const AccessPermissionFormSubHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spaces.normal};
    width: 100%;
    background-color: white;
    padding: ${Spaces.large} ${Spaces.normal};
    border-radius: ${StyleHelpers.radiusSmall};
`

const MainSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.normal};
`

const ExplanatoryText = styled.div`
    font-weight: 600;
    font-size: 15px;
`

const MoreWrapper = styled.div`
    flex-grow: 1;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

const AccessPermissionFormBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${Spaces.normal};
    align-items: flex-start;

    padding: ${Spaces.large} 0 0 ${Spaces.large};
`

const RestrictAccessForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${Spaces.normal};
    align-items: flex-start;
    width: 100%;

    padding-left: ${Spaces.xLarge};
`

const ConditionsTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.small};

    font-size: 18px;
    font-weight: 600;
    color: ${Colors.grayDark};
`

const ConditionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.normal};

    padding-left: ${Spaces.xLarge};
`

const Selector = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px;

    border-radius: 20px;
    border: 1px solid ${Colors.primary};
    cursor: pointer;
`

const SelectorOption = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;

    font-size: 14px;
    padding: 3px ${Spaces.normal};
    border-radius: 20px;
    color: ${(props) => (props.selected ? Colors.background : Colors.primary)};
    background: ${(props) => (props.selected ? Colors.primary : Colors.background)};

    &:hover {
        background: ${(props) => (props.selected ? Colors.primaryHover : Colors.grayLight)};
    }
`

export { Selector, SelectorOption }
