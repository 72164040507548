import { useContext, useEffect } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useActor } from "@xstate/react"
import { ConfigProvider, notification } from "antd"
import styled from "styled-components"

import { GlobalStateContext } from "."
import "./App.css"
import LogoNav from "./components/LogoNav"
import { Colors } from "./components/global"

import DesignerPage from "./pages/Designer"
import SimulatorPage from "./pages/Simulator"
import Projects from "./pages/Projects"
import AuthPage from "./pages/AuthPage"

function App() {
    const { authService } = useContext(GlobalStateContext)
    const [state] = useActor(authService)
    const isAuthenticated = state.matches("checkAuth.authenticated")

    const [notificationsApi, notificationsContextHolder] = notification.useNotification({})

    if (state.context.debug) {
        useEffect(() => {
            const cleanContext: any = { ...state.context }
            delete cleanContext.projectConfig
            console.log(
                `Auth machine state value changed:\n${JSON.stringify(state.value, null, 4)};\n\n\nWith context:\n${JSON.stringify(
                    cleanContext,
                    null,
                    4
                )}.`
            )
        }, [state.value])
    }

    return (
        <AppWrapper>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: Colors.primary,
                    },
                    // components: {
                    //     Input: {
                    //         boxShadow: "0 0 0 3px rgba(0, 0, 0, 0.2)",
                    //     },
                    // },
                }}
            >
                {notificationsContextHolder}
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/projects"
                            element={isAuthenticated ? <Projects notificationsApi={notificationsApi} /> : <Navigate to={"/"} />}
                        />
                        <Route path=":projectId">
                            <Route path="" element={<Navigate to={"/"} />} />
                            <Route
                                path="designer"
                                element={isAuthenticated ? <DesignerPage notificationsApi={notificationsApi} /> : <Navigate to={"/"} />}
                            />
                            <Route
                                path="simulator"
                                element={isAuthenticated ? <SimulatorPage notificationsApi={notificationsApi} /> : <Navigate to={"/"} />}
                            />
                            {/* <Route path="explorer" element={} /> */}
                            {/* <Route path="metrics" element={} /> */}
                        </Route>
                        <Route path="/auth" element={isAuthenticated ? <Navigate to={"/projects"} /> : <AuthPage />} />
                        <Route path="/" element={isAuthenticated ? <Navigate to={"/projects"} /> : <Navigate to={"/auth"} />} />
                        <Route path="*" element={<Navigate to={"/"} />} />
                    </Routes>
                    {isAuthenticated && (
                        <>
                            <LogoNav />
                        </>
                    )}
                </BrowserRouter>
            </ConfigProvider>
        </AppWrapper>
    )
}

const AppWrapper = styled.div``

export default App
