import { useEffect, useState } from "react"
import styled from "styled-components"
import { Badge, Form, Tooltip, Button, Input, Typography, Tree, Checkbox } from "antd"
import {
    DeploymentUnitOutlined,
    CarryOutOutlined,
    PlayCircleTwoTone,
    ProfileOutlined,
    CodeOutlined,
    FilterOutlined,
    FormOutlined,
} from "@ant-design/icons"
import { CopyBlock, github } from "react-code-blocks"
import type { DataNode } from "antd/es/tree"

import { StyleHelpers, Colors, Spaces, ItemWithFadeInAnimation } from "../global"
import { TreeWrapper } from "../Designer/AccessPermissions/AllowedFieldsTree"
import { GetEntityAllFields } from "../../helpers/functions"
import { MutationForm } from "./SignUpProfile"
import { FilterRules, FilterRulesProps } from "./FilterRules"
import type { SubfieldHelper } from "../../coreTypes/endpoints"
import type { Config, EntityField } from "../../coreTypes/config"
import type { EndpointData } from "../../machines/SimulatorModel"

const { Title } = Typography

interface EntityFieldExtended extends EntityField {
    pathKey: string
}

interface DataNodeExtended extends DataNode {
    subObjectName?: string
    connectedEntityName?: string
    originalField?: EntityFieldExtended
}

const renderTreeField = (
    field: EntityFieldExtended,
    selectField: (pathKey: string) => void,
    unselectField: (pathKey: string) => void,
    isSelected = false,
    isGray = false
): DataNodeExtended => {
    // console.log(JSON.stringify({ RENDER_TREE_FIELD: field }))
    let selectable = true
    if (["SUBOBJECT", "CONNECTION_BELONGSTO", "CONNECTION_CONTAINS"].includes(field.type)) {
        selectable = false
    }

    return {
        title: (
            <TreeTitle isGray={isGray}>
                {selectable && (
                    <Checkbox
                        checked={isSelected}
                        onClick={() => {
                            if (!isSelected) {
                                selectField(field.pathKey)
                            } else {
                                unselectField(field.pathKey)
                            }
                        }}
                    />
                )}
                {field.connectedEntityName && <DeploymentUnitOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.grayDark }} />}
                <div className="fieldName">{field.name}</div>
                <Tooltip mouseEnterDelay={0.4} title={field.description}>
                    {/* <InfoCircleOutlined style={{ fontSize: StyleHelpers.iconSize, color: Colors.primary }} /> */}
                    <BreakText>{field.description}</BreakText>
                </Tooltip>
            </TreeTitle>
        ),
        key: field.pathKey,
        icon: <CarryOutOutlined />,
        selectable: false,
        isLeaf: selectable,
        //
        subObjectName: field.subObjectName,
        connectedEntityName: field.connectedEntityName,
        originalField: field,
    }
}

export const EndpointItem = (props: {
    index: number
    type: "GET" | "LIST" | "CREATE" | "UPDATE" | "DELETE"
    titleName: string
    entityDescription?: string
    isInputOutputAll: boolean
    outputFields?: string[]
    projectConfig: Config
    entityName: string
    outputSubfields?: SubfieldHelper
    inputFields?: string[]
    openFilter?: () => void
    openSelectorFields?: () => void
    openCodeSample: () => void
    openMutationForm?: () => void
    setMutationFormValues?: (values: any) => void
    mutationFormValues?: any
    selectEndpoint: () => void
    closeEndpoint: () => void
    collapseAll: () => void
    run: () => void
    isAuthenticated: boolean
    isSelected: boolean
    isFilter?: boolean
    isSelectorFields?: boolean
    isCodeSample: boolean
    isMutationForm?: boolean
    selectField: (pathKey: string) => void
    unselectField: (pathKey: string) => void
    filterRules?: FilterRulesProps
    endpointData?: EndpointData
    setObjectId: (objectId: string) => void
}) => {
    const {
        index,
        type,
        titleName,
        entityDescription,
        isInputOutputAll,
        outputFields,
        projectConfig,
        entityName,
        outputSubfields,
        inputFields,
        openFilter,
        openSelectorFields,
        openCodeSample,
        openMutationForm,
        setMutationFormValues,
        mutationFormValues,
        selectEndpoint,
        closeEndpoint,
        collapseAll,
        run,
        isAuthenticated,
        isSelected,
        isFilter,
        isSelectorFields,
        isCodeSample,
        isMutationForm,
        selectField,
        unselectField,
        filterRules,
        endpointData,
        setObjectId,
    } = props

    const titleWrapper = (
        <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
            {titleName}
        </Title>
    )

    const initTreeData: DataNodeExtended[] = []
    const [treeData, setTreeData] = useState(initTreeData)
    // console.log(JSON.stringify({ TREE_DATA: treeData.map((e) => e.key) }, null, 4))

    const allFields = GetEntityAllFields(projectConfig, entityName, true, true)
    const mutationFields = GetEntityAllFields(projectConfig, entityName, false, true)

    if (!allFields) {
        // console.error("GetEntityAllFields returned undefined")
        return (
            <EndpointItemWrapper index={index}>
                <HeaderWrapper>
                    {entityDescription ? (
                        <Tooltip mouseEnterDelay={0.4} title={entityDescription} overlayInnerStyle={{ width: "300px" }}>
                            {`${titleWrapper} – Undefined endpoint fields`}
                        </Tooltip>
                    ) : (
                        titleWrapper
                    )}
                </HeaderWrapper>
            </EndpointItemWrapper>
        )
    }

    useEffect(() => {
        // console.log(JSON.stringify({ isSelected, treeData, allFields }))
        if (isSelected) {
            // Handling treeData only for selected endpoint
            if (treeData.length === 0) {
                // To render treeData for the first time
                if (allFields) {
                    for (const field of allFields) {
                        // console.log(JSON.stringify({ isInputOutputAll, field }))
                        if (isInputOutputAll || outputFields?.includes(field.name)) {
                            if (field.connectionUnderlyingField)
                                initTreeData.push(
                                    renderTreeField(
                                        { ...field.connectionUnderlyingField, pathKey: field.connectionUnderlyingField.name },
                                        selectField,
                                        unselectField,
                                        endpointData?.selectorFields?.includes(field.connectionUnderlyingField.name)
                                    )
                                )

                            initTreeData.push(
                                renderTreeField(
                                    { ...field, pathKey: field.name },
                                    selectField,
                                    unselectField,
                                    endpointData?.selectorFields?.includes(field.name)
                                )
                            )
                        }
                    }
                    setTreeData(initTreeData)
                }
            } else {
                // console.log("SELECTED FIELDS", titleName)
                // re-render treeData for selectedFields to update checkboxes excluding first time
                const newTreeData = [...treeData]
                for (const n in newTreeData) {
                    newTreeData[n] = rerenderNode(newTreeData[n])
                }
                setTreeData(newTreeData)
            }
        }
    }, [props.isSelected, props.endpointData])

    const rerenderNode = (node: DataNodeExtended, isGray?: boolean) => {
        // console.log(JSON.stringify({ node }))
        if (node.children) {
            isGray = !isGray ?? node.connectedEntityName !== undefined

            if (node.subObjectName !== undefined && outputSubfields) {
                const keyParts = String(node.key).split(".")
                let lastKey: SubfieldHelper | "+" | undefined = outputSubfields?.[keyParts[0]]
                for (let kp = 1; kp <= keyParts.length; kp++) {
                    lastKey = lastKey && lastKey !== "+" ? lastKey[keyParts[kp]] : undefined
                }
                if (lastKey && lastKey !== "+" && node.originalField?.name && !Object.keys(lastKey).includes(node.originalField?.name)) isGray = true
            }
            for (const c in node.children) {
                node.children[c] = rerenderNode(node.children[c], isGray)
            }
        } else {
            node = renderTreeField(
                { ...node.originalField!, pathKey: String(node.key) },
                selectField,
                unselectField,
                endpointData?.selectorFields?.includes(String(node.key)),
                isGray
            )
        }
        return node
    }

    const onLoadData = ({ key, children, subObjectName, connectedEntityName }: DataNodeExtended) =>
        new Promise<void>((resolve) => {
            // console.log(JSON.stringify({ ON_LOAD_DATA: { key, subObjectName, connectedEntityName } }, null, 4))
            if (children) {
                resolve()
                return
            }

            const innerFields: DataNodeExtended[] = []
            let fields: EntityField[] | undefined
            if (subObjectName) {
                fields = GetEntityAllFields(projectConfig, subObjectName, true, true)
            } else if (connectedEntityName) {
                fields = GetEntityAllFields(projectConfig, connectedEntityName, true, true)
                // console.log(JSON.stringify({ GET_ENTITY_ALL_FIELDS: { name: connectedEntityName, fields } }))
            }
            if (fields) {
                for (const subfield of fields) {
                    let isGray = connectedEntityName !== undefined

                    if (subObjectName !== undefined && outputSubfields) {
                        const keyParts = String(key).split(".")
                        let lastKey: SubfieldHelper | "+" | undefined = outputSubfields?.[keyParts[0]]
                        for (let kp = 1; kp <= keyParts.length; kp++) {
                            lastKey = lastKey && lastKey !== "+" ? lastKey[keyParts[kp]] : undefined
                        }
                        if (!lastKey) continue
                        if (lastKey !== "+" && !Object.keys(lastKey).includes(subfield.name)) isGray = true
                    }

                    if (subfield.connectionUnderlyingField) {
                        innerFields.push(
                            renderTreeField(
                                { ...subfield.connectionUnderlyingField, pathKey: `${key}.${subfield.connectionUnderlyingField.name}` },
                                selectField,
                                unselectField,
                                endpointData?.selectorFields?.includes(`${key}.${subfield.connectionUnderlyingField.name}`),
                                isGray
                            )
                        )
                    }
                    innerFields.push(
                        renderTreeField(
                            { ...subfield, pathKey: `${key}.${subfield.name}` },
                            selectField,
                            unselectField,
                            endpointData?.selectorFields?.includes(`${key}.${subfield.name}`),
                            isGray
                        )
                    )
                }
            }

            setTreeData((origin) => updateTreeData(origin, key, innerFields))
            resolve()
        })

    const updateTreeData = (list: DataNodeExtended[], key: React.Key, children: DataNodeExtended[]): DataNodeExtended[] =>
        list.map((node) => {
            if (node.key === key) {
                return {
                    ...node,
                    children,
                }
            }
            if (node.children) {
                return {
                    ...node,
                    children: updateTreeData(node.children, key, children),
                }
            }
            return node
        })

    const [form] = Form.useForm()
    if (mutationFormValues) form.setFieldsValue(mutationFormValues)

    const preventErrs = endpointData?.preventRunErrors ?? []
    if (!isAuthenticated && !preventErrs.includes("Add/select auth session for the API access"))
        preventErrs.push("Add/select auth session for the API access")

    let runButton = (
        <Button
            type="text"
            loading={endpointData?.status === "loading"}
            disabled={preventErrs.length != 0 || endpointData?.status === "loading"}
            icon={
                <PlayCircleTwoTone
                    style={{ fontSize: StyleHelpers.largeIconSize }}
                    twoToneColor={preventErrs.length != 0 ? Colors.grayDark : Colors.primaryRed}
                />
            }
            onClick={(e) => {
                e.stopPropagation()
                run()
            }}
        />
    )
    if (endpointData?.status === "loading") {
        runButton = (
            <Badge size="small" status="processing">
                {runButton}
            </Badge>
        )
    } else if (endpointData?.status === "error" || endpointData?.status === "success") {
        runButton = (
            <Badge size="small" status="error">
                {runButton}
            </Badge>
        )
    }
    if (preventErrs.length != 0)
        runButton = (
            <Tooltip
                title={
                    <>
                        {preventErrs.map((pe, ind) => (
                            <div key={ind}>{`- ${pe};`}</div>
                        ))}
                    </>
                }
                overlayInnerStyle={{ width: "300px" }}
            >
                {runButton}
            </Tooltip>
        )

    return (
        <EndpointItemWrapper index={index} selected={isSelected} onClick={() => (isSelected ? closeEndpoint() : selectEndpoint())}>
            <HeaderWrapper>
                {entityDescription ? (
                    <Tooltip mouseEnterDelay={0.4} title={entityDescription} overlayInnerStyle={{ width: "300px" }}>
                        {titleWrapper}
                    </Tooltip>
                ) : (
                    titleWrapper
                )}

                {type === "GET" && (
                    <Input
                        placeholder="ID of the object to get"
                        title="ID of the object to get"
                        style={{ width: "unset" }}
                        value={endpointData?.objectId}
                        onChange={(e) => setObjectId(e.target.value)}
                        onClick={(e) => {
                            if (isSelected) e.stopPropagation()
                        }}
                    />
                )}
                {type === "LIST" && (
                    <div>
                        <Badge size="small" count={filterRules?.filters?.length}>
                            <Button
                                type="text"
                                icon={
                                    <Tooltip mouseEnterDelay={0.4} mouseLeaveDelay={0} title={"Query filter rules"}>
                                        <FilterOutlined
                                            style={{
                                                fontSize: StyleHelpers.largeIconSize,
                                                color: isSelected && isFilter ? Colors.primary : Colors.grayDark,
                                            }}
                                        />{" "}
                                    </Tooltip>
                                }
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (isFilter && isSelected) {
                                        collapseAll()
                                    } else {
                                        openFilter!()
                                    }
                                }}
                            />
                        </Badge>
                    </div>
                )}
                {["UPDATE"].includes(type) && (
                    <Input
                        placeholder="ID of the object to update"
                        title="ID of the object to update"
                        style={{ width: "unset" }}
                        value={endpointData?.objectId}
                        onChange={(e) => setObjectId(e.target.value)}
                        onClick={(e) => {
                            if (isSelected) e.stopPropagation()
                        }}
                    />
                )}
                {["DELETE"].includes(type) && (
                    <Input
                        placeholder="ID of the object to delete"
                        title="ID of the object to delete"
                        style={{ width: "unset" }}
                        value={endpointData?.objectId}
                        onChange={(e) => setObjectId(e.target.value)}
                        onClick={(e) => {
                            if (isSelected) e.stopPropagation()
                        }}
                    />
                )}
                {["CREATE", "UPDATE"].includes(type) && (
                    <Badge size="small" count={endpointData?.inputs && Object.keys(endpointData.inputs)?.length}>
                        <Button
                            type="text"
                            icon={
                                <Tooltip mouseEnterDelay={0.4} mouseLeaveDelay={0} title={"Mutation form"}>
                                    <FormOutlined
                                        style={{
                                            fontSize: StyleHelpers.largeIconSize,
                                            color: isMutationForm && isSelected ? Colors.primary : Colors.grayDark,
                                        }}
                                    />
                                </Tooltip>
                            }
                            onClick={(e) => {
                                e.stopPropagation()
                                if (isMutationForm && isSelected) {
                                    collapseAll()
                                } else {
                                    openMutationForm!()
                                }
                            }}
                        />
                    </Badge>
                )}
                {/* TODO: add support of selector fieleds for "CREATE", "UPDATE" => need to implement back-end first */}
                {["GET", "LIST"].includes(type) && (
                    <Badge size="small" count={endpointData?.selectorFields?.length}>
                        <Button
                            type="text"
                            icon={
                                <Tooltip mouseEnterDelay={0.4} mouseLeaveDelay={0} title={"Endpoint response selctor fields"}>
                                    <ProfileOutlined
                                        style={{
                                            fontSize: StyleHelpers.largeIconSize,
                                            color: isSelected && isSelectorFields ? Colors.primary : Colors.grayDark,
                                        }}
                                    />
                                </Tooltip>
                            }
                            onClick={(e) => {
                                e.stopPropagation()
                                if (isSelectorFields && isSelected) {
                                    collapseAll()
                                } else {
                                    openSelectorFields!()
                                }
                            }}
                        />
                    </Badge>
                )}

                <Button
                    type="text"
                    icon={
                        <Tooltip mouseEnterDelay={0.4} mouseLeaveDelay={0} title={"Code sample"}>
                            <CodeOutlined
                                style={{ fontSize: StyleHelpers.largeIconSize, color: isSelected && isCodeSample ? Colors.primary : Colors.grayDark }}
                            />
                        </Tooltip>
                    }
                    onClick={(e) => {
                        e.stopPropagation()
                        if (isCodeSample && isSelected) {
                            collapseAll()
                        } else {
                            openCodeSample()
                        }
                    }}
                />

                {runButton}
            </HeaderWrapper>

            <CollapsibleWrapper onClick={(e) => e.stopPropagation()}>
                {isSelected && isFilter && (
                    <FiltersWrapperCollapsible>
                        <FilterRules
                            projectConfig={projectConfig}
                            filterFields={allFields.filter((af) => isInputOutputAll || outputFields?.includes(af.name))}
                            {...filterRules!}
                        />
                    </FiltersWrapperCollapsible>
                )}
                {isSelected && isSelectorFields && (
                    <TreeWrapperCollapsible>
                        <Tree
                            style={{
                                background: Colors.background,
                                width: "100%",
                            }}
                            showLine={false}
                            showIcon={false}
                            treeData={treeData}
                            loadData={onLoadData}
                        />
                    </TreeWrapperCollapsible>
                )}
                {isSelected && isCodeSample && (
                    <MutationFormWrapperCollapsible>
                        <CopyBlock
                            text={JSON.stringify(endpointData?.payload, null, 4)} // TODO: need to format query to be multiline clean look
                            language="json"
                            showLineNumbers={false}
                            wrapLongLines
                            theme={github}
                        />
                    </MutationFormWrapperCollapsible>
                )}
                {isSelected && isMutationForm && (
                    <MutationFormWrapperCollapsible>
                        <MutationForm
                            form={form}
                            setMutationFormValues={setMutationFormValues}
                            fields={mutationFields}
                            noImmutable={"UPDATE" == type}
                            allowedFields={inputFields}
                            projectConfig={projectConfig}
                        />
                    </MutationFormWrapperCollapsible>
                )}
            </CollapsibleWrapper>
        </EndpointItemWrapper>
    )
}

export const EndpointItemWrapper = styled(ItemWithFadeInAnimation)<{ index: number; selected?: boolean }>`
    border-radius: ${StyleHelpers.radiusSmall};
    padding: ${Spaces.normal};
    margin-bottom: ${Spaces.normal};
    background-color: white;
    border: ${(props) => (props.selected ? `2px solid ${Colors.primary}` : "unset")};
    cursor: pointer;

    animation-delay: ${(props: { index: number }) => props.index * 0.15}s; /* delay animation start for each item */

    box-shadow: ${StyleHelpers.staticBoxShadow};

    :hover {
        box-shadow: ${(props) => (props.selected ? "unset" : StyleHelpers.lightBoxShadow)};
        transform: ${(props) => (props.selected ? "unset" : "scale(1.01)")};
    }
`

export const HeaderWrapper = styled.div`
    // FIXME: clickable with standard roll in animation
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: ${Spaces.normal};

    & > :first-child {
        width: 40%;
    }

    & > :nth-child(2) {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    & > :last-child {
        margin-left: ${Spaces.medium};
    }
`

const CollapsibleWrapper = styled.div`
    // FIXME: clickable with roll out animation
    width: 100%;
`

const TreeWrapperCollapsible = styled(TreeWrapper)`
    width: calc(100% - ${Spaces.normal} * 2);

    margin: ${Spaces.medium} ${Spaces.normal} 0 ${Spaces.normal};
    padding-top: ${Spaces.normal};
    border-top: 1px solid ${Colors.grayNormal};

    .ant-tree-list {
        background: white !important;
    }
    .ant-tree-treenode {
        overflow: hidden;
    }
`

const MutationFormWrapperCollapsible = styled.div`
    width: calc(100% - ${Spaces.normal} * 2);

    margin: ${Spaces.medium} ${Spaces.normal} 0 ${Spaces.normal};
    padding-top: ${Spaces.xLarge};
    border-top: 1px solid ${Colors.grayNormal};
`

const FiltersWrapperCollapsible = styled.div`
    width: calc(100% - ${Spaces.normal} * 2);

    margin: ${Spaces.medium} ${Spaces.normal} 0 ${Spaces.normal};
    padding-top: ${Spaces.xLarge};
    border-top: 1px solid ${Colors.grayNormal};
`

const TreeTitle = styled.div<{ isGray: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${Spaces.normal};
    /* overflow: hidden; */
    .fieldName {
        color: ${(props) => (props.isGray ? Colors.grayNormal : "black")};
        font-weight: bold;
        font-size: 17px;
    }
    & > :last-child {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-grow: 1;
    }
`

const BreakText = styled.div`
    display: block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-right: ${Spaces.medium};
    color: ${Colors.grayNormal};
`
